import React, { useEffect, RefCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ProfileFormInput, ProfileFormSchema } from '@src/validators/profileFormSchema';
import { Grid, TextField } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuthToken } from '@src/services/queries/useAuthQuery';
import { useUpdateUser } from '@src/services/queries/useUsersQuery';
import Swal from 'sweetalert2';

interface propsUpdateProfile {
  open: boolean;
  handleClose: RefCallback<string>;
}

export default function UpdateProfile(props: propsUpdateProfile) {
  const { t } = useTranslation();
  const { data } = useAuthToken();
  const updateUserQuery = useUpdateUser();

  const { open, handleClose } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileFormInput>({
    resolver: zodResolver(ProfileFormSchema),
  });

  useEffect(() => {
    if (data) {
      setValue('firstName', data?.firstName);
      setValue('lastName', data?.lastName);
      setValue('email', data?.email);
      setValue('tel', data?.tel);
    }
  }, [data, setValue]);

  function handleCancel(e: MouseEvent) {
    handleClose('updateAcct');
  }

  const handleSubmitForm: SubmitHandler<any> = async (formData) => {
    try {
      formData.id = data.id;
      await updateUserQuery.mutateAsync(formData, {
        onSuccess: () => {
          Swal.fire({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'แก้ไขข้อมูลสำเร็จแล้ว',
            confirmButtonText: 'ตกลง',
            customClass: {
              popup: 'popup-backend',
            },
          }).then(() => {
            window.location.reload();
          });
        },
        onError: () => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: 'ไม่สามารถแก้ไขข้อมูลได้',
            confirmButtonText: 'ตกลง',
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px', // Adjust the value as needed
        },
      }}
    >
      <DialogTitle>
        <div className="columns">
          <div className="column is-6">
            <h3 className="content-sub-header content-left font-bold">
              {t('SETTING_PAGE.UPDATE_ACCOUNT')}
            </h3>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers={false}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="container p-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label>
                  {t('SETTING_PAGE.UPDATE_ACCOUNT_DIAG.FIRST_NAME')}

                  <span className="text-red-500">*</span>
                </label>
                <TextField
                  {...register('firstName')}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>
                  {t('SETTING_PAGE.UPDATE_ACCOUNT_DIAG.LAST_NAME')}
                  <span className="text-red-500">*</span>
                </label>
                <TextField
                  {...register('lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>
                  {t('SETTING_PAGE.UPDATE_ACCOUNT_DIAG.EMAIL')}
                  <span className="text-red-500">*</span>
                </label>
                <TextField
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>
                  {t('SETTING_PAGE.UPDATE_ACCOUNT_DIAG.PHONE')}
                  <span className="text-red-500">*</span>
                </label>
                <TextField
                  size="small"
                  {...register('tel')}
                  error={!!errors.tel}
                  helperText={errors.tel?.message}
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{ sx: { borderRadius: 1.6, backgroundColor: 'white' } }}
                />
              </Grid>
            </Grid>
          </div>

          <DialogActions>
            <div className="column">
              <div className="buttons" style={{ justifyContent: 'center' }}>
                <button
                  className="button is-rounded is-danger App"
                  type="button"
                  onClick={handleCancel}
                >
                  {t('BUTTONS.CANCEL')}
                </button>
                <button className="button is-rounded is-info App" type="submit">
                  {t('BUTTONS.SAVE')}
                </button>
              </div>
            </div>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
