import { zodResolver } from '@hookform/resolvers/zod';

import { Avatar, Button, Grid, Stack, TextField } from '@mui/material';
import Iconify from '@src/component/layout/admin/components/iconify';
import { useUpdateUser, useUserById } from '@src/services/queries/useUsersQuery';
import { ProfileFormInput, ProfileFormSchema } from '@src/validators/profileFormSchema';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

const ProfileSection = ({ userId }: { userId: string }) => {
  const { data, isLoading } = useUserById(userId);
  const updateUserQuery = useUpdateUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ProfileFormInput>({
    resolver: zodResolver(ProfileFormSchema),
  });

  useEffect(() => {
    if (data) {
      setValue('firstName', data?.firstName);
      setValue('lastName', data?.lastName);
      setValue('email', data?.email);
      setValue('tel', data?.tel);
    }
  }, [data, setValue]);
  const onSubmit = async (dataForm: any) => {
    dataForm.id = userId;
    await updateUserQuery.mutateAsync(dataForm, {
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          title: 'สำเร็จ!',
          text: 'แก้ไขข้อมูลสำเร็จแล้ว',
          confirmButtonText: 'ตกลง',
          customClass: {
            popup: 'popup-backend',
          },
        }).then(() => {
          window.location.reload();
        });
      },
      onError: () => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถแก้ไขข้อมูลได้',
          confirmButtonText: 'ตกลง',
        });
      },
    });
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="w-full max-w-xl px-2 py-3">
      <div className="max-w-xl bg-white shadow-xl ">
        <header className=" p-6 rounded-lg ">
          <div className="flex items-center gap-4">
            <Stack direction="row" spacing={2}>
              <Avatar sx={{ width: 90, height: 90 }} src={data?.image?.url} alt={data?.firstName} />
            </Stack>
            <div className="grid gap-1">
              <h1 className="text-2xl font-bold">
                {data?.firstName} {data?.lastName}
              </h1>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <span>{data?.email}</span>
              </div>
            </div>
          </div>
        </header>
        <div className=" px-5 pb-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label>ชื่อ</label>
                <TextField
                  {...register('firstName')}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>นามสกุล</label>
                <TextField
                  {...register('lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>อีเมล</label>
                <TextField
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>เบอร์โทร</label>
                <TextField
                  size="small"
                  {...register('tel')}
                  error={!!errors.tel}
                  helperText={errors.tel?.message}
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{ sx: { borderRadius: 1.6, backgroundColor: 'white' } }}
                />
              </Grid>
            </Grid>

            <div className="flex justify-end  pt-4">
              <Button
                type="submit"
                variant="contained"
                color={'primary'}
                sx={{
                  borderRadius: '8px',
                  alignItems: 'center',
                  gap: 0.5,
                  color: 'black',
                  backgroundColor: '#B4E380',
                  '&:hover': {
                    backgroundColor: '#88D66C',
                  },
                }}
              >
                <Iconify icon="iconamoon:profile-bold" />
                {'Save Update'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
