import { TableCell, TableRow } from '@mui/material';

type TableEmptyRowsProps = {
  emptyRows: number;
  height: number;
};
export default function TableEmptyRows({ emptyRows, height }: TableEmptyRowsProps) {
  if (!emptyRows) {
    return null;
  }
  return (
    <TableRow
      sx={{
        ...(height && {
          height: height * emptyRows,
        }),
      }}
    >
      <TableCell colSpan={4} />
    </TableRow>
  );
}
