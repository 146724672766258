import { IProfile } from '@src/types';

export const StateProfile = (profile: IProfile) => {
  localStorage.setItem('profile', JSON.stringify(profile));
};

export const Profile = () => {
  const profileString = localStorage.getItem('profile');
  return profileString ? JSON.parse(profileString) : null;
};
