import React, { useState, RefCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import {
  getSessionStorageData,
  setSessionStorageData,
  hashValue,
} from '../../constants/screen/util';
import { useForm, SubmitHandler } from 'react-hook-form';
import ChangePasswordElement from '../Setting/changePassword';

interface propsSignUp {
  open: boolean;
  handleClose: RefCallback<any>;
}
interface propsPasswordObj {
  isValid: boolean;
  password: string;
}
interface registerData {
  fullname: string | null;
  email: string;
  phone: string;
  password: string;
}

export default function SignUp(props: propsSignUp) {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>('');
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<registerData>();

  function handleCancel(e: any) {
    setPassword('');
    setValidPassword(false);
    reset();
    handleClose(e);
  }

  const handleRegistration: SubmitHandler<registerData> = (e) => {
    if (validPassword) {
      let registerDataObj: registerData = {
        fullname: e.fullname,
        email: e.email,
        phone: e.phone,
        password: hashValue(password),
      };
      let ssStorage = getSessionStorageData('registrationState');
      setSessionStorageData('registrationState', Object.assign(ssStorage, registerDataObj));
      Swal.fire({
        html:
          `<p>` +
          t('SIGN_UP_PAGE.ALERT_POPUP.REGISTER_SUCCESS.TITLE') +
          `</p><p>` +
          t('SIGN_UP_PAGE.ALERT_POPUP.REGISTER_SUCCESS.TEXT') +
          `<p>`,
        icon: 'success',
        width: '20em',
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      handleCancel(e);
    }
  };

  const handleValidPassword = (value: propsPasswordObj) => {
    if (value?.isValid) {
      setPassword(value.password);
    }
    setValidPassword(value.isValid);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px', // Adjust the value as needed
        },
      }}
    >
      <form onSubmit={handleSubmit(handleRegistration)} action="#">
        <DialogTitle id="scroll-dialog-title">
          <div className="columns">
            <div className="column is-6">
              <h3 className="content-sub-header content-left font-bold">
                {t('SIGN_UP_PAGE.HEADER')}
              </h3>
            </div>
          </div>
        </DialogTitle>
        <hr style={{ backgroundColor: '#828693', marginTop: '-5px', marginBottom: '15px' }} />
        <DialogContent dividers={false} style={{ height: '700px' }}>
          <div className="columns" style={{ marginTop: '-35px' }}>
            <div className="column is-12">
              <div className="field">
                <p className="control is-expanded">
                  <p className="content-left">
                    {t('SIGN_UP_PAGE.FULLNAME')}
                    <span className="has-text-danger">*</span>
                  </p>
                  <input
                    className="input"
                    type="text"
                    id="setFullname"
                    placeholder={t('SIGN_UP_PAGE.FULLNAME')}
                    style={{
                      borderRadius: '10px',
                      borderColor: errors.fullname ? 'red' : '',
                      background: errors.fullname ? '' : '',
                    }}
                    {...register('fullname', {
                      required:
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                        t('SIGN_UP_PAGE.FULLNAME') +
                        t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG'),
                    })}
                  />
                  {errors?.fullname ? (
                    <p className="content-error">{errors.fullname.message}</p>
                  ) : (
                    <p className="content-error">&nbsp;</p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="columns" style={{ marginTop: '-30px' }}>
            <div className="column is-12">
              <div className="field">
                <p className="control is-expanded">
                  <p className="content-left">
                    {t('SIGN_UP_PAGE.EMAIL')}
                    <span className="has-text-danger">*</span>
                  </p>
                  <input
                    className="input"
                    type="email"
                    id="setEmail"
                    placeholder={t('SIGN_UP_PAGE.EMAIL')}
                    style={{
                      borderRadius: '10px',
                      borderColor: errors.email ? 'red' : '',
                      background: errors.email ? '' : '',
                    }}
                    {...register('email', {
                      required:
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                        t('SIGN_UP_PAGE.EMAIL') +
                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: t('ERROR_MSG_FORM.ERROR_EMAIL_MSG'),
                      },
                    })}
                  />
                  {errors?.email ? (
                    <p className="content-error">{errors.email.message}</p>
                  ) : (
                    <p className="content-error">&nbsp;</p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="columns" style={{ marginTop: '-30px', marginBottom: '-18px' }}>
            <div className="column is-12">
              <div className="field">
                <p className="control is-expanded">
                  <p className="content-left">
                    {t('SIGN_UP_PAGE.PHONE')}
                    <span className="has-text-danger">*</span>
                  </p>
                  <input
                    className="input"
                    type="tel"
                    id="setphone"
                    placeholder={t('SIGN_UP_PAGE.PHONE')}
                    style={{
                      borderRadius: '10px',
                      borderColor: errors.phone ? 'red' : '',
                      background: errors.phone ? '' : '',
                    }}
                    {...register('phone', {
                      required:
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                        t('SIGN_UP_PAGE.PHONE') +
                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: t('ERROR_MSG_FORM.ERROR_PHONE_MSG'),
                      },
                    })}
                  />
                  {errors?.phone ? (
                    <p className="content-error">{errors.phone.message}</p>
                  ) : (
                    <p className="content-error">&nbsp;</p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <ChangePasswordElement handleValidPasswordProps={handleValidPassword} />
        </DialogContent>
        <DialogActions>
          <div className="column">
            <div className="buttons content-center">
              <button
                className="button is-rounded is-danger App"
                type="button"
                onClick={handleCancel}
              >
                {t('BUTTONS.CANCEL')}
              </button>
              <button className="button is-rounded is-link App" type="submit">
                {t('BUTTONS.REGISTRATION')}
              </button>
            </div>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
