import { Credentials } from '@src/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../axios';
import axios from 'axios';
import { UserPolicyAcceptance } from '@src/component/layout/admin/common/types';
import { removeCookie } from '@src/utils/remove-cookie';
import { config } from '@src/config';

export const useAuthLogin = () => {
  return useMutation({
    mutationFn: async (credentials: Credentials) => {
      try {
        const { data } = await api.post('/auth/login', credentials);
        return data;
      } catch (error) {
        throw error;
      }
    },
    // onSuccess: (data) => {
    //   if (data && data?.accesstoken) {
    //     const token = data.accesstoken;
    //     const payload = decodeJwt(token);
    //     const date = new Date(0);
    //     date.setUTCSeconds(payload.exp);
    //     document.cookie = `zero-carbon-auth.session-token=${token}; path=/; expires=${date.toUTCString()};`;
    //     console.log(token);
    //   }
    // },
  });
};

export const useAuthGeneralToken = async (token: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/token`, {
      headers: {
        Authorization: `Bearer ${token}`, // Use the token here
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Get the auth token from server. If the token is invalid, it will
 * remove the cookie and redirect to /login.
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const useAuthToken = () => {
  return useQuery({
    queryKey: ['authToken'],
    queryFn: async () => {
      try {
        const { data } = await api.get(`/auth/token`);
        return data;
      } catch (error: any) {
        if (error.response?.status === 401) {
          removeCookie(config.keyNameCookie);
          window.location.href = '/login';
        }
        throw error;
      }
    },
  });
};

export const useCheckAccept = async (token: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/policies/acception`, {
      headers: {
        Authorization: `Bearer ${token}`, // Use the token here
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const useAccept = () => {
  return useMutation({
    mutationFn: async ({
      token,
      accept,
    }: {
      token: string | undefined;
      accept: UserPolicyAcceptance;
    }) => {
      try {
        const { data, status } = await axios.post(
          `${process.env.REACT_APP_API_URL}/policies/acception`,
          accept,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Use the token here
              'Content-Type': 'application/json',
            },
          }
        );
        return { data, status };
      } catch (error) {
        throw error;
      }
    },
  });
};

export const useGetAccept = () => {
  return useQuery({
    queryKey: ['accept'],
    queryFn: async () => {
      try {
        const { data } = await api.get(`/policies/acception`);
        return data;
      } catch (error) {
        throw error;
      }
    },
  });
};
