import cn from '@src/utils/class-names';
import React from 'react';

interface CardDetailsProps {
  children?: React.ReactNode;
  className?: string;
  title?: string;
}

const CardDetails = ({ title, className, children }: CardDetailsProps) => {
  return (
    <div className={cn('bg-white p-4 rounded-lg shadow', className)}>
      <div className="py-1 text-navy-600 font-semibold text-lg"> {title} </div>
      {children}
    </div>
  );
};

export default CardDetails;
