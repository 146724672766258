import React from 'react';
import CountUp from 'react-countup';
import cn from '@src/utils/class-names';

type TotalCarbonProps = {
  title: string;
  value: number;
  color?: string;
  type?: string;
};

const TotalCarbon = ({ title, value, color = 'bg-green-800', type = 'คน' }: TotalCarbonProps) => {
  return (
    <div className="col-span-2">
      <div className={cn('px-4 py-2 font-semibold text-white text-center', color)}>{title}</div>
      <div className="px-4 py-8 font-semibold bg-gray-50 text-navy-500 text-center text-5xl">
        <CountUp start={0} end={value ?? 0} duration={2.75} decimals={0} />
      </div>
      <div className={cn('px-4 py-2 font-semibold text-white text-center', color)}>{type}</div>
    </div>
  );
};

export default TotalCarbon;
