import React, { useState, MouseEvent, useEffect } from 'react';
import { Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ErrorNotify } from '../../constants/screen/toast';
import { useTranslation } from 'react-i18next';
import { getSessionStorageData, setSessionStorageData } from '../../constants/screen/util';
import { useCategories } from '@src/services/queries/useCategoriesQuery';

export default function RegisterMice() {
  const navigate = useNavigate();
  const categoriesQuery = useCategories();

  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);

  type subCategoryType = {
    id: number | string;
    categoryId: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  type MiceType = {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    isTravel: boolean;
    isDesktop: boolean;
    isMobile: boolean;
    subCategory: subCategoryType[];
  };

  useEffect(() => {
    let tmpCreateData = getSessionStorageData('tmpCreateData');
    if (tmpCreateData?.subCategoryId) {
      setSelectedValue(tmpCreateData?.subCategoryId);
    }
  }, []);

  const handleChange = (event: MouseEvent<HTMLElement>, nextView: string | number) => {
    //@ts-ignore
    if (nextView !== undefined) setSelectedValue(nextView);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  function handleClickNext(e: MouseEvent<HTMLElement>) {
    if (!selectedValue) {
      ErrorNotify('กรุณาเลือกประเภทโครงการ');
    } else {
      let objData = {
        subCategoryId: selectedValue,
        status: 'pre',
        paging: 2,
      };
      let tmpCreateData = getSessionStorageData('tmpCreateData');
      setSessionStorageData('tmpCreateData', Object.assign(tmpCreateData, objData));
      navigate('/register/pre/2');
    }
  }

  if (categoriesQuery.isLoading) return <div>Loading...</div>;
  return (
    <div>
      <div className="columns is-mobile">
        <div className="column mt-3">
          <h1 className="content-header content-left">{t('MICE_PAGE.HEADER')}</h1>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column">
          <ToggleButtonGroup
            color="success"
            value={selectedValue}
            exclusive
            onChange={handleChange}
          >
            {categoriesQuery.data?.data?.map((item: MiceType) =>
              item?.subCategory?.map((sub: subCategoryType, index: number) => (
                <ToggleButton
                  value={sub.id as string}
                  aria-label="list"
                  style={{
                    borderColor: '#E2E2E2',
                    width: '350px',
                    borderRadius: '30px',
                    display: 'block',
                    marginInlineStart: '10px',
                    textTransform: 'none',
                  }}
                >
                  <h1 className="content-header" style={{ fontSize: 80 }}>
                    {index === 0 ? 'M' : index === 1 ? 'I' : index === 2 ? 'C' : 'E'}
                  </h1>
                  <br />
                  {/* <h1 className="content-header-s">{t('MICE_PAGE.M_EN')}</h1> */}
                  <h1 className="content-header-s" style={{ fontSize: '22px' }}>
                    {sub?.name}
                  </h1>
                  <Radio
                    {...controlProps(sub.id as string)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                        color: '#49B90D',
                        mt: 2,
                      },
                    }}
                  />
                </ToggleButton>
              ))
            )}
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="columns">
        <div className="column is-4 is-offset-4" style={{ alignItems: 'right' }}>
          <br />
          <br />
          <button
            className="button is-rounded is-large is-fullwidth is-success App"
            onClick={handleClickNext}
          >
            {t('BUTTONS.NEXT')}
          </button>
        </div>
      </div>
    </div>
  );
}
