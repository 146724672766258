import PropTypes from 'prop-types';
import { memo, forwardRef, ReactNode } from 'react';

import Box from '@mui/material/Box';

import { SxProps } from '@mui/material';

// ----------------------------------------------------------------------
interface ScrollbarProps {
  children?: ReactNode;
  sx?: SxProps;
}

// const Main: React.FC<MainProps> = ({ children, sx, ...other }) => {
const Scrollbar = forwardRef<HTMLAnchorElement, ScrollbarProps>(
  ({ children, sx, ...other }, ref) => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    if (mobile) {
      return (
        <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
          {children}
        </Box>
      );
    }

    return <div>{children}</div>;
  }
);

Scrollbar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default memo(Scrollbar);
