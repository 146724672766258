import FormResetPassword from '@src/component/layout/admin/sections/form-reset-password';
import { Profile } from '@src/utils/state-profile';
import React, { useEffect, useState } from 'react';

const ProfileResetPassword = () => {
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    const profile = Profile();
    if (profile) {
      const mapId = atob(profile.mapId);
      setUserId(mapId);
    }
  }, [setUserId]);
  return { userId } ? <FormResetPassword userId={userId} /> : null;
};

export default ProfileResetPassword;
