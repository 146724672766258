import ProfileSection from '@src/component/layout/admin/sections/profile';
import { Profile } from '@src/utils/state-profile';
import { useEffect, useState } from 'react';
/**
 * Page that displays the profile of the currently logged in user.
 *
 * @returns {JSX.Element} The profile page element.
 */
const ProfilePage = (): JSX.Element => {
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    const profileData = Profile();
    if (profileData) {
      const mapId = atob(profileData.mapId);
      setUserId(mapId);
    }
  }, []);

  return <>{userId ? <ProfileSection userId={userId} /> : null}</>;
};

export default ProfilePage;
