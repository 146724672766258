import React, { RefCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface propsPolicyPrivacy {
  open: boolean;
  handleClose: RefCallback<any>;
}

export default function PolicyPrivacy(props: propsPolicyPrivacy) {
  const { t } = useTranslation();
  const { open, handleClose } = props;

  function handleCancel(e: MouseEvent) {
    handleClose('policyPrivacy');
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px', // Adjust the value as needed
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="columns">
          <div className="column is-6">
            {/* <h3 className="content-sub-header content-left">{t("SETTING_PAGE.POLICY_PRIVACY")}</h3> */}
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers={false} style={{ height: '550px', overflow: 'hidden' }}>
        {/* <div className="box" style={{ borderRadius: "30px" }}> */}
        <div className="detail-scroll-dialog">
          <div className="columns">
            <div className="column">
              <h2 className="content-header-s content-center font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.HEADER')}
              </h2>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h2
                className="content-header-s font-bold"
                style={{ marginTop: '-15px', marginBottom: '15px' }}
              >
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER1')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT1')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER2')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT2')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT2_1')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT2_2')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT2_3')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT2_4')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER3')}
              </h2>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE1')}
              </h3>
              <p className="content-left">{t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT3')}</p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE2')}
              </h3>
              <p className="content-left">{t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT4')}</p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE3')}
              </h3>
              <p className="content-left">{t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT5')}</p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE4')}
              </h3>
              <p className="content-left">{t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT6')}</p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE5')}
              </h3>
              <p className="content-left">{t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT7')}</p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE6')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT8')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER4')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT9')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER5')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT10')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT10_1')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT10_2')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT10_3')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER6')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_1')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_2')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_3')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_4')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_5')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_6')}
              </p>
              <p className="content" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT11_7')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER7')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT12')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER8')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT13')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE7')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT14')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE8')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT15')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE9')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT16')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE10')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT17')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE11')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT18')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE12')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT19')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE13')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT20')}
              </p>
              <h3 className="content-sub-header font-bold">
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE14')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT21')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER9')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT22')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER10')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT23')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER11')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT24')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT25')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER12')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT26')}
              </p>
              <h2 className="content-header-s font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.SUB_HEADER13')}
              </h2>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT27')}
              </p>
              <h3 className="content-sub-header font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE15')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT28')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT29')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT30')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT31')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT32')}
              </p>
              <h3 className="content-sub-header font-bold" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.TITLE16')}
              </h3>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT33')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT34')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT35')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT36')}
              </p>
              <p className="content-left" style={{ marginBottom: '15px' }}>
                {t('SETTING_PAGE.POLICY_PRIVACY_DIAG.CONTENT37')}
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </DialogContent>
      <DialogActions>
        <div className="column">
          <div className="buttons content-center">
            <button className="button is-rounded is-link App" type="button" onClick={handleCancel}>
              {t('BUTTONS.BACKWARD')}
            </button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}
