import {  FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';

type OptionsProps = {
  label: string;
  value: string;
};
type SelectZeroProps = {
  label?: string;
  options?: OptionsProps[];
  placeholder?: string;
  setValue: (ag: string) => void;
};

const SelectZero = ({
  label,
  placeholder = 'select...',
  options = [],
  setValue,
}: SelectZeroProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(event.target.value);
    setValue(value);
  };
  const cssSelect = {
    borderRadius: 5,
    height: 32,
    backgroundColor: '#6CB2A1',
    borderColor: '#51746B',

    textAlign: 'center',
    color: '#FFF',
  };
  return (
    <div className="flex items-center">
      <label>{label}</label>
      <FormControl
        sx={{
          m: 1,
          width: 150,
          border: '1px',
          borderRadius: 3,
        }}
        variant="outlined"
      >
        <Select value={selectedValue} onChange={handleChange} displayEmpty sx={cssSelect}>
          <MenuItem value="">{placeholder}</MenuItem>
          {options.map((option) => (
            <MenuItem value={option?.value}>{option?.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectZero;
