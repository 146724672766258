import React, { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UpdateProfile from './updateProfile';
import PDPA from './pdpa';
import PolicyPrivacy from './policyPrivacy';
import PolicyRefunds from './policyRefunds';
import ContactUs from './contactUs';
import Swal from 'sweetalert2';
import { removeCookie } from '@src/utils/remove-cookie';
import { config } from '@src/config';
import ResetPasswordDialog from './ResetPasswordDialog';

export default function Setting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const [openPdpaSetting, setOpenPdpaSetting] = useState(false);
  const [openPolicyPrivacy, setOpenPolicyPrivacy] = useState(false);
  const [openPolicyRefunds, setOpenPolicyRefunds] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState<boolean>(false);

  const handleClickOpen = (e: MouseEvent) => {
    const { id } = e.target as HTMLButtonElement;
    if (id.includes('updateAcct')) {
      setOpenUpdateProfile(true);
    } else if (id.includes('pdpaSetting')) {
      console.log('open', openPdpaSetting);
      setOpenPdpaSetting(true);
      console.log('open f', openPdpaSetting);
    } else if (id.includes('policyPrivacy')) {
      setOpenPolicyPrivacy(true);
    } else if (id.includes('policyRefunds')) {
      setOpenPolicyRefunds(true);
    } else if (id.includes('contactUs')) {
      setOpenContactUs(true);
    }
  };
  const handleClickClose = (componentID: string) => {
    const id = componentID;
    if (id.includes('updateAcct')) {
      setOpenUpdateProfile(false);
    } else if (id.includes('pdpaSetting')) {
      setOpenPdpaSetting(false);
    } else if (id.includes('policyPrivacy')) {
      setOpenPolicyPrivacy(false);
    } else if (id.includes('policyRefunds')) {
      setOpenPolicyRefunds(false);
    } else if (id.includes('contactUs')) {
      setOpenContactUs(false);
    }
  };

  function handleClickCancel() {
    navigate('/');
  }

  function handleClickLogout() {
    Swal.fire({
      html:
        `<b>` +
        t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.TITLE') +
        `</b><p>` +
        t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.TEXT') +
        `</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.CANCEL_BTN'),
      confirmButtonText: t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.CONFIRM_BTN'),
      width: '20em',
    }).then((result) => {
      if (result.isConfirmed) {
        removeCookie(config.keyNameCookie);
        navigate('/login');
      }
    });
  }

  function handleClickDeleteAcct() {
    Swal.fire({
      html:
        `<b>` +
        t('SETTING_PAGE.ALERT_POPUP.DELETE_ACCOUNT_POPUP.TITLE') +
        `</b><p>` +
        t('SETTING_PAGE.ALERT_POPUP.DELETE_ACCOUNT_POPUP.TEXT') +
        `</p>`,
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: t('SETTING_PAGE.ALERT_POPUP.DELETE_ACCOUNT_POPUP.CANCEL_BTN'),
      confirmButtonText: t('SETTING_PAGE.ALERT_POPUP.DELETE_ACCOUNT_POPUP.CONFIRM_BTN'),
      width: '20em',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/');
      }
    });
  }

  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <br />
          <br />
          <h1 className="content-header content-left">{t('SETTING_PAGE.HEADER')}</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <h3 className="content-sub-header content-left">{t('SETTING_PAGE.SUBHEADER_ACCOUNT')}</h3>
        </div>
        <div className="column is-offset-5">
          <h3 className="content-sub-header content-left">
            {t('SETTING_PAGE.SUBHEADER_DEL_ACCOUNT')}
          </h3>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <button
            className="button is-medium is-fullwidth content-left"
            id="updateAcct"
            onClick={handleClickOpen}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-user" id="updateAcctIcon"></i>
            </span>
            <span className="content-sub-header" id="updateAcctText">
              {t('SETTING_PAGE.UPDATE_ACCOUNT')}
            </span>
          </button>
        </div>
        <div className="column is-3">
          <button
            className="button is-medium is-fullwidth content-left"
            id="changePassword"
            onClick={() => setOpenResetPassword(true)}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-lock" id="changePasswordIcon"></i>
            </span>
            <span className="content-sub-header" id="changePasswordText">
              {t('SETTING_PAGE.CHANGE_PASSWORD')}
            </span>
          </button>
        </div>
        <div className="column is-3 is-offset-2">
          <button
            className="button is-medium is-fullwidth content-left is-danger is-light"
            id="delAcct"
            onClick={handleClickDeleteAcct}
            style={{ borderRadius: '10px', borderWidth: '1px', borderColor: '#dbdbdb' }}
          >
            <span className="icon" style={{ color: '#C54A4A' }}>
              <i className="fa-solid fa-trash" id="delAcctIcon"></i>
            </span>
            <span className="content-sub-header" id="delAcctText">
              {t('SETTING_PAGE.DELETE_ACCOUNT')}
            </span>
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <h3 className="content-sub-header content-left">{t('SETTING_PAGE.SUBHEADER_PDPA')}</h3>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <button
            className="button is-medium is-fullwidth content-left"
            id="pdpaSetting"
            onClick={handleClickOpen}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-shield" id="pdpaSettingIcon"></i>
            </span>
            <span className="content-sub-header" id="pdpaSettingText">
              {t('SETTING_PAGE.PDPA')}
            </span>
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <h3 className="content-sub-header content-left">{t('SETTING_PAGE.SUBHEADER_POLICY')}</h3>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <button
            className="button is-medium is-fullwidth content-left"
            id="policyPrivacy"
            onClick={handleClickOpen}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-file" id="policyPrivacyIcon"></i>
            </span>
            <span className="content-sub-header" id="policyPrivacyText">
              {t('SETTING_PAGE.POLICY_PRIVACY')}
            </span>
          </button>
        </div>
        <div className="column is-3">
          <button
            className="button is-medium is-fullwidth content-left"
            id="policyRefunds"
            onClick={handleClickOpen}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-box-archive" id="policyRefundsIcon"></i>
            </span>
            <span className="content-sub-header" id="policyRefundsText">
              {t('SETTING_PAGE.POLICY_REFUNDS')}
            </span>
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <h3 className="content-sub-header content-left">
            {t('SETTING_PAGE.SUBHEADER_CONTACTUS')}
          </h3>
        </div>
      </div>
      <div className="columns">
        <div className="column is-3">
          <button
            className="button is-medium is-fullwidth content-left"
            id="contactUs"
            onClick={handleClickOpen}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-phone" id="contactUsIcon"></i>
            </span>
            <span className="content-sub-header" id="contactUsText">
              {t('SETTING_PAGE.CONTACTUS')}
            </span>
          </button>
        </div>
        <div className="column is-3 is-offset-5">
          <button
            className="button is-medium is-fullwidth content-left"
            id="logout"
            type="button"
            onClick={handleClickLogout}
            style={{ borderRadius: '10px' }}
          >
            <span className="icon" style={{ color: '#6CC94C' }}>
              <i className="fa-solid fa-arrow-right-from-bracket" id="logoutIcon"></i>
            </span>
            <span className="content-sub-header" id="logoutText">
              {t('SETTING_PAGE.LOGOUT')}
            </span>
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <br />
          <br />
          <div className="buttons" style={{ justifyContent: 'center' }}>
            <button
              className="button is-rounded is-danger App is-medium"
              type="button"
              onClick={handleClickCancel}
            >
              {t('BUTTONS.BACK_TO_HOME')}
            </button>
          </div>
        </div>
      </div>

      <ResetPasswordDialog open={openResetPassword} onClose={() => setOpenResetPassword(false)} />
      <UpdateProfile open={openUpdateProfile} handleClose={handleClickClose} />
      <PDPA open={openPdpaSetting} refPage={'setting'} handleClose={handleClickClose} />
      <PolicyPrivacy open={openPolicyPrivacy} handleClose={handleClickClose} />
      <PolicyRefunds open={openPolicyRefunds} handleClose={handleClickClose} />
      <ContactUs open={openContactUs} handleClose={handleClickClose} />
    </div>
  );
}
