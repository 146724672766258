import { Box, Card, Stack } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
type WidgetSummaryProps = {
  title?: string;
  value?: number | undefined;
  type?: string;
  detail?: string;
  positionType?: string | 'right' | 'bottom';
  classTitle?: string;
};
const WidgetSummary = ({
  title,
  value,
  type,
  positionType = 'right',
  detail = '',
  classTitle,
}: WidgetSummaryProps) => {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        px: 2.5,
        py: 2.5,
        borderRadius: 3,
        boxShadow: 1.5,
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Box
          className={classTitle}
          fontSize={'xl'}
          sx={{ fontFamily: 'Kanit', fontSize: '1.15rem', lineHeight: '1.25rem', fontWeight: 400 }}
        >
          {title}
        </Box>
        <Box className="text-gray-500" sx={{ fontFamily: 'Kanit', fontWeight: 600, fontSize: 22 }}>
          <CountUp start={0} end={value ?? 0} duration={10} className="" />
          {positionType === 'right' && <span className="px-2">{type}</span>}
        </Box>
        {positionType === 'bottom' && <div className="font-normal text-sm">{type}</div>}

        {detail && <div className="font-normal text-gray-500">{detail}</div>}
      </Box>
    </Card>
  );
};

export default WidgetSummary;
