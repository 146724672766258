import { Box, CircularProgress, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

const TableLoading = () => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={6} sx={{ py: 6 }}>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <CircularProgress sx={{ color: '#1AA238' }} size={50} />
          <Typography variant="h6" paragraph>
            Loading...
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableLoading;
