import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createProject, deleteProject, fetchProjects, updateProject } from './api';
import { ProjectCreate, ProjectsResponse } from '@src/types';
import Swal from 'sweetalert2';
export const handleError = async (error: unknown) => {
  if (error instanceof Error) {
    //@ts-ignore
    let message = error?.response.data?.message;
    console.log(message);
    await Swal.fire({
      title: 'Error',
      text: 'เกิดข้อผิดพลาด',
      icon: 'error',
      confirmButtonColor: '#d33',
      showConfirmButton: true,
      confirmButtonText: 'ปิดหน้าต่าง',
      customClass: {
        popup: 'popup-backend',
      },
    });
  }
};

export const useProjects = (page: number, limit: number): UseQueryResult<ProjectsResponse> => {
  return useQuery({
    queryKey: ['projects', page, limit],
    queryFn: fetchProjects,
  });
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();

  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ['projects'] });
  };

  return useMutation({
    mutationFn: (project: ProjectCreate) => createProject(project),
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export const useUpdateProject = () => {
  const queryClient = useQueryClient();

  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ['projects'] });
  };

  return useMutation({
    mutationFn: (project: ProjectCreate) => updateProject(project.id, project),
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ['projects'] });
  };
  return useMutation({
    mutationFn: (id: number) => deleteProject(id),
    onSuccess: handleSuccess,
    onError: handleError,
  });
};
