import React, { useEffect, useState, MouseEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { eventType } from '../../constants/screen/eventType';
import Logo from '../../constants/img/carbon_logo.jpeg';
import Box from '@mui/material/Box';
import { formatterNumber, formatterDate } from '../../constants/screen/util';
import { useTranslation, Trans } from 'react-i18next';

export default function Summary() {
  const navigate = useNavigate();
  const { event, id } = useParams();
  const [summaryTotal, setSummaryTotal] = useState<any>({});
  const { t } = useTranslation();

  useEffect(() => {
    let tmpCreateProjectStr = sessionStorage.getItem('tmpCreateData');
    let tmpCreateProject = tmpCreateProjectStr ? JSON.parse(tmpCreateProjectStr!) : '';
    let detailEventSession = tmpCreateProject.eventDetail ? tmpCreateProject.eventDetail : {};
    let detailEventSessionID = Object.keys(detailEventSession);
    let summaryCo2eq: any = {};
    let totalCo2eq = 0;
    eventType.map((eventTypeItem) => {
      detailEventSessionID.map((detailItem) => {
        if (detailEventSession[detailItem]?.eventCategory === eventTypeItem.id) {
          summaryCo2eq[eventTypeItem.id]
            ? (summaryCo2eq[eventTypeItem.id] += detailEventSession[detailItem]?.totalCo2eq
              ? detailEventSession[detailItem]?.totalCo2eq / 1000
              : 0)
            : (summaryCo2eq[eventTypeItem.id] = detailEventSession[detailItem]?.totalCo2eq
              ? detailEventSession[detailItem]?.totalCo2eq / 1000
              : 0);
          totalCo2eq += detailEventSession[detailItem]?.totalCo2eq
            ? detailEventSession[detailItem]?.totalCo2eq
            : 0;
        }
      });
    });
    summaryCo2eq.total = totalCo2eq / 1000;
    summaryCo2eq.totalPperson = totalCo2eq / parseFloat(tmpCreateProject.eventMember) / 1000;
    setSummaryTotal(summaryCo2eq);
  }, []);

  function handleSaveProject(e: MouseEvent<HTMLElement>) {
    let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
    let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
    tmpCreateData.summaryCo2eq = summaryTotal;
    tmpCreateData.status = event;
    tmpCreateData.paging = 5;
    if (event === 'pre') {
      let listProjectDataStr = sessionStorage.getItem('listProjectData');
      let arrProjectData = listProjectDataStr ? JSON.parse(listProjectDataStr) : [];

      let indexOfProjectTarget = arrProjectData.findIndex(
        (item: any) => item.id === tmpCreateData?.id
      );
      if (indexOfProjectTarget === -1) {
        tmpCreateData.createDate = new Date().toLocaleDateString(formatterDate);
        tmpCreateData.id = new Date().getTime();
        arrProjectData.push(tmpCreateData);
      } else {
        arrProjectData[indexOfProjectTarget] = tmpCreateData;
      }
      sessionStorage.setItem('listProjectData', JSON.stringify(arrProjectData));
      sessionStorage.setItem('tmpCreateData', '');
    } else {
      sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
    }
    navigate(event === 'post' ? '/register/post/5' : '/register/pre/5');
  }
  function handlePostProject(e: MouseEvent<HTMLElement>) {
    let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
    let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
    tmpCreateData.status = event;
    tmpCreateData.paging = 2;
    navigate('/register/post/2');
  }
  return (
    <section>
      <div className="box" style={{ marginBlockStart: '80px', borderRadius: '90px' }}>
        <div className="columns is-mobile">
          <div className="column is-offset-1">
            <br />
            <br />
            <h1 className="content-header content-left">{t('SUMMARY_PAGE.HEADER')}</h1>
          </div>
        </div>
        <div className="columns is-mobile">
          <div className="column is-5 is-offset-1">
            <div className="columns">
              <div className="column is-8">
                <h1 className="content-sub-header content-left font-bold">
                  {t('SUMMARY_PAGE.SOURCE_CO2E')}
                </h1>
                <br />
              </div>
              <div className="column is-4">
                <h1 className="content-sub-header content-left font-bold">
                  {t('SUMMARY_PAGE.SOURCE_CO2E_AMT')}
                </h1>
                <br />
              </div>
            </div>
            {eventType.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-8" style={{ marginTop: '-30px' }}>
                    <h1 className="content-left">{t(item.name)}</h1>
                    <br />
                  </div>
                  <div className="column is-4" style={{ marginTop: '-30px' }}>
                    <h1 className="content-left">
                      <b>
                        {summaryTotal[item.id]
                          ? formatterNumber.format(summaryTotal[item.id])
                          : '0.00'}
                      </b>
                      &nbsp;&nbsp;
                      {summaryTotal[item.id]
                        ? formatterNumber.format(
                          (summaryTotal[item.id] / summaryTotal.total) * 100
                        ) + '%'
                        : '0.00%'}
                    </h1>
                    <br />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="column is-4 is-offset-1">
            <Box
              display="block"
              alignItems="center"
              sx={{
                border: '5px solid grey',
                borderColor: '#C8F026',
                borderRadius: '8px',
              }}
            >
              <div className="column">
                <h1 className="content-header-s">{t('SUMMARY_PAGE.TOTAL_CO2E')}</h1>
              </div>
              <div className="column" style={{ marginTop: event === 'post' ? '-25px' : '0px' }}>
                <h1 className="content-header font-bold" style={{ color: '#26B3F0' }}>
                  {formatterNumber.format(summaryTotal.total)} {t('SUMMARY_PAGE.UNIT')}
                </h1>
              </div>
              <div className="column" style={{ marginTop: event === 'post' ? '-25px' : '0px' }}>
                <h1 className="content-header-s">{t('SUMMARY_PAGE.AVG_PER_PERSON')}</h1>
              </div>
              <div className="column" style={{ marginTop: event === 'post' ? '-25px' : '0px' }}>
                <h1 className="content-header font-bold" style={{ color: '#26B3F0' }}>
                  {formatterNumber.format(summaryTotal.totalPperson)} {t('SUMMARY_PAGE.UNIT')}
                </h1>
              </div>
            </Box>
            <br />
            {event === 'post' ? (
              <>
                <a
                  className="button is-large is-fullwidth App"
                  style={{
                    backgroundColor: '#26B3F0',
                    fontSize: '18px',
                    color: 'white',
                    height: '70px',
                  }}
                  href={Logo}
                  download
                >
                  <Trans i18nKey="DOWNLOAD_LOGO_PAGE.BUTTONS.DOWNLOAD"></Trans>
                </a>
                <br />
              </>
            ) : (
              ''
            )}
            {id === '0' ? (
              <>
                <button
                  className="button is-large is-fullwidth App"
                  style={{
                    backgroundColor: '#C8F026',
                    fontSize: '20px',
                    height: '70px',
                  }}
                  onClick={handlePostProject}
                >
                  {t('SUMMARY_PAGE.BUTTONS.CONTINUE')}
                </button>
                <br />
              </>
            ) : (
              <>
                <button
                  className="button is-large is-fullwidth App"
                  style={{
                    backgroundColor: '#C8F026',
                    fontSize: event === 'post' ? '18px' : '20px',
                    height: '70px',
                  }}
                  onClick={handleSaveProject}
                >
                  {event === 'post' ? (
                    <p>
                      <Trans i18nKey="SUMMARY_PAGE.BUTTONS.POST"></Trans>
                    </p>
                  ) : (
                    t('SUMMARY_PAGE.BUTTONS.PRE')
                  )}
                </button>
                <br />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
