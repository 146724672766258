import React, { useState, KeyboardEvent, RefCallback, MouseEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';
import Logo from '../../constants/img/pin_logo.png';
import {
  getSessionStorageData,
  setSessionStorageData,
  hashValue,
} from '../../constants/screen/util';
import { useForm, SubmitHandler } from 'react-hook-form';
import ChangePasswordElement from '../Setting/changePassword';
import './resetPassword.css';

interface propsChangePassword {
  open: boolean;
  handleClose: RefCallback<any>;
  handleOpen: RefCallback<any>;
}
interface propsChangePasswordObj {
  isValid: boolean;
  password: string;
}
interface resetPasswordStorage {
  password?: string;
  otp?: string;
  email?: string;
  isSuccess: boolean;
}

export default function ResetPassword(props: propsChangePassword) {
  const { open, handleClose, handleOpen } = props;
  const { t } = useTranslation();
  const [stateResetPassword, setStateResetPassword] = useState<number>(1);
  const [emailInput, setEmailInput] = useState<string>('');
  const [otpInput, setOtpInput] = useState<string>('');
  const [passwordChange, setPasswordChange] = useState<string>('');
  const [isDisableBtn, setIsDisableBtn] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<resetPasswordStorage>();

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleOtpChange = (value: string) => {
    //coding for OTP change here
    if (value.length !== 6) {
      setIsDisableBtn(true);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
  };

  const handleOtpComplete = (value: string) => {
    //coding when OTP fill complete
    if (value !== '000000') {
      handleClose(value);
      setIsDisableBtn(true);
      Swal.fire({
        html: `<p>` + t('FORGET_PASSWORD_PAGE.ALERT_POPUP.OTP_SUBMIT.TITLE') + `</p>`,
        icon: 'warning',
        width: '20em',
        confirmButtonText: t('FORGET_PASSWORD_PAGE.ALERT_POPUP.OTP_SUBMIT.CONFIRM_BTN'),
      }).then((result) => {
        if (result.isConfirmed) {
          handleOpen(value);
        }
      });
    } else {
      setOtpInput(value);
      setIsDisableBtn(false);
    }
  };

  const handleChangePassword = (value: propsChangePasswordObj) => {
    if (value?.isValid) {
      setPasswordChange(value.password);
    }
  };

  function handleResendOTP(e: MouseEvent<HTMLAnchorElement>) {
    //coding to resend otp here
    console.log('resend OTP');
  }

  function handleCancel(e: any) {
    setStateResetPassword(1);
    setEmailInput('');
    setPasswordChange('');
    reset();
    handleClose(e);
  }

  const handleClickNext: SubmitHandler<resetPasswordStorage> = (e) => {
    let nextState: number = stateResetPassword + 1;
    let isError: boolean = false;
    let isDone: boolean = false;
    if (stateResetPassword === 1) {
      //coding when click next for email page in forget password process
      if (!emailInput) {
        handleClose(e);
        Swal.fire({
          html:
            `<p>` +
            t('FORGET_PASSWORD_PAGE.ALERT_POPUP.EMAIL_SUBMIT.TITLE') +
            `</p><p>` +
            t('FORGET_PASSWORD_PAGE.ALERT_POPUP.EMAIL_SUBMIT.TEXT') +
            `</p>`,
          icon: 'warning',
          width: '20em',
          confirmButtonText: t('FORGET_PASSWORD_PAGE.ALERT_POPUP.EMAIL_SUBMIT.CONFIRM_BTN'),
        }).then((result) => {
          if (result.isConfirmed) {
            handleOpen(e);
          }
        });
        isError = true;
      } else {
        let storeEmailObj: resetPasswordStorage = {
          isSuccess: false,
          email: emailInput,
        };
        let ssStorage = getSessionStorageData('forgetPasswordState');
        setSessionStorageData('forgetPasswordState', Object.assign(ssStorage, storeEmailObj));
        setIsDisableBtn(true);
      }
    } else if (stateResetPassword === 2) {
      //coding when click next for otp page in forget password process
      if (!otpInput) {
        isError = true;
      } else {
        let storeOtpObj: resetPasswordStorage = {
          isSuccess: false,
          otp: otpInput,
        };
        let ssStorage = getSessionStorageData('forgetPasswordState');
        setSessionStorageData('forgetPasswordState', Object.assign(ssStorage, storeOtpObj));
      }
    } else if (stateResetPassword === 3) {
      //coding when click next for change password page in forget password process
      if (!passwordChange) {
        isError = true;
      } else {
        let storePasswordObj: resetPasswordStorage = {
          isSuccess: true,
          password: hashValue(passwordChange),
        };
        let ssStorage = getSessionStorageData('forgetPasswordState');
        setSessionStorageData('forgetPasswordState', Object.assign(ssStorage, storePasswordObj));
        Swal.fire({
          html: `<p>` + t('FORGET_PASSWORD_PAGE.ALERT_POPUP.RESET_PASSWORD_SUCCESS') + `</p>`,
          icon: 'success',
          width: '20em',
          showConfirmButton: false,
          timer: 1500,
        });
        isDone = true;
        setStateResetPassword(1);
        setEmailInput('');
        setPasswordChange('');
        reset();
        handleCancel(e);
      }
    }
    if (!isError && !isDone) {
      setStateResetPassword(nextState);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px', // Adjust the value as needed
        },
      }}
    >
      <form
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit(handleClickNext)}
        action={'#' + stateResetPassword}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="columns">
            <div className="column is-6">
              <h3 className="content-sub-header content-left font-bold">
                {t('FORGET_PASSWORD_PAGE.HEADER' + stateResetPassword)}
              </h3>
            </div>
          </div>
        </DialogTitle>
        <hr style={{ backgroundColor: '#828693', marginTop: '-5px' }} />
        <DialogContent className="dialog-content" dividers={false}>
          {stateResetPassword === 2 ? (
            <div className="columns">
              <div className="column">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
          ) : (
            ''
          )}
          {[1, 2].includes(stateResetPassword) ? (
            <div className="columns">
              <div className="column">
                <h3 className="content-sub-header font-bold">
                  {t('FORGET_PASSWORD_PAGE.TITLE' + stateResetPassword)}
                </h3>
                <p className="content-left">
                  {t('FORGET_PASSWORD_PAGE.CONTENT' + stateResetPassword)}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
          {stateResetPassword === 1 ? (
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      id="setEmail"
                      placeholder={t('FORGET_PASSWORD_PAGE.EMAIL')}
                      value={emailInput}
                      style={{
                        borderRadius: '10px',
                        borderColor: errors.email ? 'red' : '',
                        background: errors.email ? '' : '',
                      }}
                      {...register('email', {
                        required:
                          t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                          t('LOGIN_PAGE.USER_ID') +
                          t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                        onChange: (e) => {
                          handleEmailChange(e);
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: t('ERROR_MSG_FORM.ERROR_EMAIL_MSG'),
                        },
                      })}
                    />
                    {errors?.email ? (
                      <p className="content-error">{errors.email.message}</p>
                    ) : (
                      <p className="content-error">&nbsp;</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {stateResetPassword === 2 ? (
            <div>
              <div className="columns">
                <div className="column">
                  <HStack className="pin-input-group">
                    <PinInput
                      size="lg"
                      placeholder=""
                      onChange={handleOtpChange}
                      onComplete={handleOtpComplete}
                      otp={true}
                      mask={false}
                    >
                      <PinInputField className="pin-input-custom" />
                      <PinInputField className="pin-input-custom" />
                      <PinInputField className="pin-input-custom" />
                      <PinInputField className="pin-input-custom" />
                      <PinInputField className="pin-input-custom" />
                      <PinInputField className="pin-input-custom" />
                    </PinInput>
                  </HStack>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <p className="content-center">
                    <span>{t('FORGET_PASSWORD_PAGE.OTP_FOOTER')}</span>
                    <span>
                      <a className="a-clickable" onClick={handleResendOTP}>
                        {t('BUTTONS.RESEND')}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {stateResetPassword === 3 ? (
            <ChangePasswordElement handleValidPasswordProps={handleChangePassword} />
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <div className="column">
            <div className="buttons content-center">
              <button
                className="button is-rounded is-danger App"
                type="button"
                onClick={handleCancel}
              >
                {t('BUTTONS.CANCEL')}
              </button>
              <button
                className="button is-rounded is-link App"
                type="submit"
                disabled={isDisableBtn}
              >
                {t('BUTTONS.NEXT')}
              </button>
            </div>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
