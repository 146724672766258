import React from 'react';
import { Box, Card } from '@mui/material';
import { ChartBar } from '../widget-chartBar/chartBar';
import { backgroundColor, BorderColors } from '@utils/colors';

function SummaryBarChart({ dataChart = [] }: { dataChart: any[] }) {
  const getSelectName = (data: any[]) => data.map((item) => item.name);
  const getSelectValue = (data: any[]) =>
    data.map((item) => {
      return (item.value = item.total);
    });

  const selectName = getSelectName(dataChart);
  const selectValue = getSelectValue(dataChart);

  const dataSets = {
    labels: selectName,
    datasets: [
      {
        label: '',
        data: selectValue,
        backgroundColor: backgroundColor,
        borderColor: BorderColors,
        borderWidth: 1,
      },
    ],
  };
  return (
    <Card>
      <Box sx={{ pt: 2, padding: 1.5, mt: 1, height: 300 }}>
        <ChartBar displayLabel={false} dataSets={dataSets} enableMaintainAspectRatio={false} />
      </Box>
      '
    </Card>
  );
}

export default SummaryBarChart;
