import { z } from 'zod';

export const projectFormSchema = z.object({
  nameTh: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
  nameEn: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
  price: z
    .string()
    .nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
    .regex(/^\d+$/, { message: 'กรุณากรอกเฉพาะตัวเลข' }),
});
export type ProjectFormInput = z.infer<typeof projectFormSchema>;
