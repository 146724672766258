import { zodResolver } from '@hookform/resolvers/zod';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  ResetPasswordFormInput,
  ResetPasswordFormSchema,
} from '@src/validators/resetPasswordFormSchema';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUserById, useUserResetPassword } from '@src/services/queries/useUsersQuery';
import Swal from 'sweetalert2';

const FormResetPassword = ({ userId }: { userId: string }) => {
  const { data, isLoading } = useUserById(userId);
  const userResetPasswordQuery = useUserResetPassword();
  const [showPassword, setShowPassword] = useState(false);
  const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ResetPasswordFormInput>({
    resolver: zodResolver(ResetPasswordFormSchema),
  });

  const onSubmit = async (dataForm: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    try {
      await userResetPasswordQuery.mutateAsync(dataForm, {
        onSuccess: () => {
          Swal.fire({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'การตั้งค่ารหัสผ่านใหม่สำเร็จแล้ว',
            confirmButtonText: 'ตกลง',
            customClass: {
              popup: 'popup-backend',
            },
          }).then(() => {
            window.location.reload();
          });
        },
        onError: () => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: 'ไม่สามารถการตั้งค่ารหัสผ่านใหม่ได้',
            confirmButtonText: 'ตกลง',
          });
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (data) {
      setValue('email', data?.email);
    }
  }, [data, setValue]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-lg bg-white ">
      <form
        className="flex flex-col gap-4 p-5 border border-gray-300 rounded-lg "
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="text-xl font-medium">เปลี่ยนรหัสผ่าน</h3>
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-gray-700">อีเมล</label>
          <TextField
            type={'email'}
            fullWidth
            disabled
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            size="small"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-gray-700">
            รหัสผ่านใหม่ <span className="text-red-600">*</span>
          </label>
          <TextField
            type={showPassword ? 'text' : 'password'}
            fullWidth
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            size="small"
            InputProps={{
              sx: { borderRadius: 1.6, backgroundColor: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-gray-700">
            ยันยันรหัสผ่าน <span className="text-red-600">*</span>
          </label>
          <TextField
            type={ShowConfirmPassword ? 'text' : 'password'}
            fullWidth
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            size="small"
            InputProps={{
              sx: { borderRadius: 1.6, backgroundColor: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {ShowConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="flex items-center justify-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              color: 'black',
              backgroundColor: '#B4E380',
              '&:hover': {
                backgroundColor: '#88D66C',
              },
            }}
          >
            Reset password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormResetPassword;
