import React, { useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../constants/img/logo2.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import ResetPassword from '../ResetPassword';
import SignUp from '../SignUp';
import PdpaSetting from '../Setting/pdpa';
import { LANGUAGES } from '../../constants/screen/dropdownList';
import '../../App.css';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useForm, SubmitHandler } from 'react-hook-form';
import GlobeHemisphereWest from '../../constants/img/GlobeHemisphereWest.png';
import ReactCountryFlag from 'react-country-flag';
import Swal from 'sweetalert2';
import './login.css';
import {
  useAuthLogin,
  useAuthGeneralToken,
  useCheckAccept,
} from '@src/services/queries/useAuthQuery';
import { decodeJwt } from '@src/utils/decode-jwt';
import { StateProfile } from '@src/utils/state-profile';

interface loginData {
  email: string;
  password: string;
}
export default function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openResetPasswordDiag, setOpenResetPasswordDiag] = useState<boolean>(false);
  const [openSignUpDiag, setOpenSignUpDiag] = useState<boolean>(false);
  const [openPdpaDiag, setOpenPdpaDiag] = useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [flagCaptcha, setFlagCaptcha] = useState<boolean>(false);

  const { mutate, data, error } = useAuthLogin();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<loginData>({
    defaultValues: {
      email: 'zerouser@zero.com',
      password: 'P@ssw0rd',
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      // If the user is authenticated, redirect to home page
      navigate('/');
    }
  }, [navigate]);
  function onChangeCaptcha(value: any) {
    //coding for captcha is change here
    if (value) {
      setFlagCaptcha(true);
    }
  }
  function onChangeGuest() {
    //coding when click Guest button
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const onChangeLang = (e: MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;
    i18n.changeLanguage(id);
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      setTimeout(() => {
        trigger(); // Re-validate the form to update error messages
      }, 50);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, trigger]);

  const FetchUserFormToken = async (token: string) => {
    const payload = decodeJwt(token);
    const date = new Date(0);
    date.setUTCSeconds(payload.exp);
    document.cookie = `zero-carbon-auth.session-token=${token}; path=/; expires=${date.toUTCString()};`;
    const response = await useAuthGeneralToken(token);
    const acceptResponse = await useCheckAccept(token);
    if (response) {
      StateProfile({
        firstName: response?.firstName,
        lastName: response?.lastName,
        email: response?.email,
        profileImgId: response?.profileImgId,
        image: response?.image,
        authType: response?.authType,
        mapId: btoa(response?.id),
      });
      Swal.fire({
        title: i18n.language === 'en' ? 'Login successfully!' : 'เข้าสู่ระบบสำเร็จ',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (response.role === 'MEMBER') {
          const { data } = acceptResponse;
          if (data && data.length > 0) {
            window.location.href = '/';
          } else {
            setOpenPdpaDiag(true);
          }
        } else {
          window.location.href = '/admin';
        }
      });
    }
  };
  const handleLogin: SubmitHandler<loginData> = (e) => {
    // coding for click login

    if (e.email && e.password && flagCaptcha) {
      mutate({ email: e.email, password: e.password });
    } else {
      // if login failed
      Swal.fire({
        html: `<p>` + t('LOGIN_PAGE.LOGIN_FAILED') + `</p>`,
        icon: 'error',
        width: '20em',
        confirmButtonText: t('FORGET_PASSWORD_PAGE.ALERT_POPUP.OTP_SUBMIT.CONFIRM_BTN'),
      }).then((result) => {
        if (result.isConfirmed) {
          // handleOpen(value)
        }
      });
      if (!e.password) {
      }
      if (!e.email) {
      }
    }
  };

  function handleClickResetPassword(e: MouseEvent<HTMLAnchorElement>) {
    setOpenResetPasswordDiag(true);
  }
  function handleCloseResetPassword(e: MouseEvent) {
    setOpenResetPasswordDiag(false);
  }

  function handleClickSignUp(e: MouseEvent<HTMLAnchorElement>) {
    setOpenSignUpDiag(true);
  }

  function handleCloseSignUp(e: MouseEvent) {
    setOpenSignUpDiag(false);
  }

  function handleClosePdpa(e: MouseEvent) {
    setOpenPdpaDiag(false);
  }

  function showPassword(e: MouseEvent) {
    let obj = document.getElementById('setPassword') as HTMLInputElement;
    obj.type === 'text' ? (obj.type = 'password') : (obj.type = 'text');

    const togglePassword = document.getElementById('togglePassword') as HTMLElement;
    if (togglePassword.classList.contains('fa-eye')) {
      togglePassword.classList.remove('fa-eye');
      togglePassword.classList.add('fa-eye-slash');
    } else {
      togglePassword.classList.remove('fa-eye-slash');
      togglePassword.classList.add('fa-eye');
    }
  }

  if (error) {
    console.log(error);
  }

  useEffect(() => {
    if (data && data.accesstoken) {
      FetchUserFormToken(data.accesstoken);
    }
  }, [data, FetchUserFormToken]);

  const keyRECAPTCHA = process.env.REACT_APP_RECAPTCHA_KEY;

  return (
    <div className="is-background-login">
      <div className="column" style={{ justifyContent: 'center', display: 'flex' }}>
        <div className="box box-dialog" style={{ width: '520px', height: '760px' }}>
          <div className="columns is-mobile" style={{ marginTop: '-28px' }}>
            <div className="column is-9 is-offset-1">
              <br />
              <h1 className="content-header-s content-left">{t('LOGIN_PAGE.HEADER')}</h1>
            </div>
            <div className="column">
              <Box sx={{ flexGrow: 0, marginTop: '30px' }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img src={GlobeHemisphereWest} width="28px" alt='logo-login' />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {LANGUAGES?.map(({ code, label, flag }) => (
                    <MenuItem key={code} value={code} id={code} onClick={onChangeLang}>
                      <div className="column" style={{ marginTop: '-15px', marginBottom: '-10px' }}>
                        <p className="content-left">{label}</p>
                      </div>
                      <div
                        className="column content-right"
                        style={{ marginTop: '-15px', marginBottom: '-10px' }}
                      >
                        <ReactCountryFlag
                          countryCode={flag}
                          svg
                          style={{ width: '1.5em', height: '1.5em' }}
                          title={flag}
                        />
                      </div>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
          <div className="columns is-mobile">
            <div className="column is-3 is-offset-1">
              <img src={Logo} width={'200px'} style={{ marginBlockStart: '18px' }} alt='logo' />
            </div>
            <div
              className="column is-8"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h1 className="content-header content-left font-bold" style={{ color: '#027BC3' }}>
                Zero Carbon <br />
                Platform
              </h1>
              {/* <h1 className='content-header content-left font-bold' style={{ color : '#027BC3' }}>Platform</h1> */}
            </div>
          </div>
          <form
            onSubmit={handleSubmit(handleLogin)}
          // onKeyDown={(e) => {
          //   handleKeyDown(e, handleLogin);
          // }}
          >
            <div className="column is-10 is-offset-1" style={{ marginTop: '-20px' }}>
              <div className="field">
                <div className="control is-expanded">
                  <p className="content-left">{t('LOGIN_PAGE.USER_ID')}</p>
                  <input
                    className="input"
                    type="text"
                    id="setUsername"
                    placeholder={t('LOGIN_PAGE.USER_ID_PLACEHOLDER')}
                    style={{
                      borderRadius: '10px',
                      borderColor: errors.email ? 'red' : '',
                      background: errors.email ? '' : '',
                    }}
                    {...register('email', {
                      required:
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                        t('LOGIN_PAGE.USER_ID') +
                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: t('ERROR_MSG_FORM.ERROR_EMAIL_MSG'),
                      },
                    })}
                  />
                  {errors?.email ? (
                    <p className="content-error">{errors.email.message}</p>
                  ) : (
                    <p className="content-error">&nbsp;</p>
                  )}
                </div>
              </div>
            </div>
            <div className="column is-10 is-offset-1" style={{ marginTop: '-15px' }}>
              <div className="field">
                <div className="control is-expanded">
                  <p className="content-left">{t('LOGIN_PAGE.PASSWORD')}</p>
                  <input
                    className="input"
                    type="password"
                    id="setPassword"
                    placeholder={t('LOGIN_PAGE.PASSWORD_PLACEHOLDER')}
                    style={{
                      borderRadius: '10px',
                      borderColor: errors.password ? 'red' : '',
                      background: errors.password ? '' : '',
                    }}
                    {...register('password', {
                      required:
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                        t('LOGIN_PAGE.PASSWORD') +
                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                    })}
                  />
                  <i
                    className="fa-solid fa-eye-slash"
                    id="togglePassword"
                    style={{
                      cursor: 'pointer',
                      marginInlineStart: '-30px',
                      marginTop: '13px',
                      position: 'absolute',
                    }}
                    onClick={showPassword}
                  ></i>
                  {errors?.password ? (
                    <p className="content-error">{errors.password.message}</p>
                  ) : (
                    <p className="content-error">&nbsp;</p>
                  )}
                </div>
              </div>
            </div>
            <div className="column is-11" style={{ marginTop: '-33px' }}>
              <p className="content-right a-clickable">
                <a href="#" onClick={handleClickResetPassword}>
                  {t('BUTTONS.FORGET_PASSWORD')}
                </a>
              </p>
              <br />
            </div>
            <div className="captcha-container">
              {keyRECAPTCHA && <ReCAPTCHA sitekey={keyRECAPTCHA} onChange={onChangeCaptcha} />}
            </div>
            <div
              className="column"
              style={{ marginTop: '-10px', justifyContent: 'center', display: 'flex' }}
            >
              <button
                className="button is-large is-fullwidth App"
                type="submit"
                style={{
                  backgroundColor: '#5A6ACE',
                  color: '#FFFFFF',
                  fontSize: '17px',
                  borderRadius: '15px',
                  width: '300px',
                }}
                disabled={!flagCaptcha}
              >
                {t('BUTTONS.LOGIN')}
              </button>
            </div>
            <div
              className="column"
              style={{ marginTop: '-10px', justifyContent: 'center', display: 'flex' }}
            >
              <GoogleLogin
                locale="en"
                onSuccess={(credentialResponse) => {
                  // coding for sign in with google success here
                  console.log(credentialResponse);
                }}
                onError={() => {
                  // coding for sign in with google failed here
                  console.log('Login Failed');
                }}
              />
            </div>
            <div
              className="column"
              style={{ marginTop: '-10px', justifyContent: 'center', display: 'flex' }}
            >
              <button
                className="button is-large App"
                type="button"
                style={{
                  backgroundColor: '#1E202E',
                  color: '#FFFFFF',
                  fontSize: '17px',
                  borderRadius: '15px',
                  width: '300px',
                }}
                onChange={onChangeGuest}
              >
                {t('BUTTONS.GUEST')}
              </button>
            </div>
            <div className="column is-11" style={{ marginTop: '-15px' }}>
              <p className="content-right">
                <span>{t('LOGIN_PAGE.SIGN_UP')}</span>
                <span style={{ color: '#5A6ACE', fontSize: '16px' }}>
                  <a onClick={handleClickSignUp}>{t('BUTTONS.SIGN_UP')}</a>
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
      <ResetPassword
        open={openResetPasswordDiag}
        handleClose={handleCloseResetPassword}
        handleOpen={handleClickResetPassword}
      />
      <SignUp open={openSignUpDiag} handleClose={handleCloseSignUp} />
      <PdpaSetting
        open={openPdpaDiag}
        handleClose={handleClosePdpa}
        refPage="login"
        token={data?.accesstoken}
      />
    </div>
  );
}
