import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HeaderZero } from '../../components/header-zero';
import UsersTable from './user-table';

const UserSection = ({ title }: { title: string }) => {
  return (
    <>
      <Helmet>
        <title> {title ?? ''} | Zero Carbon </title>
      </Helmet>
      <HeaderZero title={title ?? ''} />
      <UsersTable />
    </>
  );
};

export default UserSection;
