import React from 'react';

function LoadingSkeletonScreen() {
  return (
    <div className="animate-pulse space-y-4 p-4">
      {/* Section 1: Overview Information */}
      <div className="bg-gray-200 rounded-md h-8 w-1/3"></div>
      <div className="bg-gray-200 rounded-md h-8 w-2/3"></div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <div className="bg-gray-200 rounded-md h-6 w-full"></div>
          <div className="bg-gray-200 rounded-md h-6 w-5/6"></div>
          <div className="bg-gray-200 rounded-md h-6 w-4/6"></div>
        </div>
        <div className="space-y-2">
          <div className="bg-gray-200 rounded-md h-6 w-full"></div>
          <div className="bg-gray-200 rounded-md h-6 w-5/6"></div>
          <div className="bg-gray-200 rounded-md h-6 w-4/6"></div>
        </div>
      </div>

      {/* Section 2: Contact Information */}
      <div className="bg-gray-200 rounded-md h-8 w-1/4"></div>
      <div className="bg-gray-200 rounded-md h-8 w-1/2"></div>
      <div className="bg-gray-200 rounded-md h-8 w-1/3"></div>
      <div className="bg-gray-200 rounded-md h-8 w-1/3"></div>
      {/* Section 3: Summary */}
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gray-200 rounded-md h-24 w-full"></div>
        <div className="bg-gray-200 rounded-md h-24 w-full"></div>
        <div className="bg-gray-200 rounded-md h-24 w-full"></div>
      </div>

      <div className="grid grid-cols-6 gap-4">
        <div className="bg-gray-200 rounded-md h-72 col-span-4"></div>
        <div className="bg-gray-200 rounded-md h-72 col-span-2"></div>
      </div>
    </div>
  );
}

export default LoadingSkeletonScreen;
