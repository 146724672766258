import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import ChangePassword from './changePassword';
import { useTranslation } from 'react-i18next';
import { useUserResetPassword } from '@src/services/queries/useUsersQuery';
import Swal from 'sweetalert2';
import { useAuthToken } from '@src/services/queries/useAuthQuery';

interface ResetPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
}
const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const userResetPasswordQuery = useUserResetPassword();
  const { data } = useAuthToken();

  const onSubmit = async (dataForm: {
    password: string;
    confirmPassword: string;
    email: string;
  }) => {
    try {
      dataForm.email = data?.email;
      await userResetPasswordQuery.mutateAsync(dataForm, {
        onSuccess: () => {
          Swal.fire({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'การตั้งค่ารหัสผ่านใหม่สำเร็จแล้ว',
            confirmButtonText: 'ตกลง',
          }).then(() => {
            window.location.reload();
          });
        },
        onError: () => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: 'ไม่สามารถการตั้งค่ารหัสผ่านใหม่ได้',
            confirmButtonText: 'ตกลง',
          });
        },
      });
    } catch (error) {}
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px',
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="columns">
          <div className="column is-6">
            <h3 className="content-sub-header content-left font-bold">
              {t('SETTING_PAGE.CHANGE_PASSWORD')}
            </h3>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <ChangePassword onClose={onClose} onHandleSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordDialog;
