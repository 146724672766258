import { Paper, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

const TableNoData = ({ query }: { query: string }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={6} sx={{ py: 4 }}>
        <Paper
          sx={{
            textAlign: 'center',
            pb: 4,
          }}
        >
          <Typography variant="h6" paragraph>
            Not found
          </Typography>

          <Typography variant="body2">
            No results found for &nbsp;
            <strong>&quot;{query}&quot;</strong>.
            <br /> Try checking for typos or using complete words.
          </Typography>
        </Paper>
      </TableCell>
    </TableRow>
  );
};

export default TableNoData;
