import React, { useEffect, useState, MouseEvent } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { green,  yellow} from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import DashboardLogo from '../../constants/img/Character_TGO_08.png';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SummaryGHG from './summaryGHG';
import './dashboard.css';
import Iconify from '@src/component/layout/admin/components/iconify';
import { useEventsALLQuery } from '@src/services/queries/useEventsQuery';
import { EventEmission } from '@src/types';
import { formatDateEn } from '@src/utils/format-date-th';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CECECE',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
export default function Dashboard() {
  interface listProjectVar {
    id: string;
    name: string;
    subName: string;
    date: string;
    action: React.JSX.Element;
    status: React.JSX.Element;
    statusName: string;
    endDateEvent: string;
  }
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listProject, setListProject] = useState<Array<listProjectVar>>([]);
  const [listProjectTarget, setListProjectTarget] = useState<Array<listProjectVar>>([]);
  const [activeTabId, setActiveId] = useState<string>('all');
  const [openPostEvent, setOpenPostEvent] = useState<boolean>(false);
  const [idProjectTarget, setIdProjectTarget] = useState<string>('');
  const [openSummary, setOpenSummary] = useState<boolean>(false);

  const [events, setEvents] = useState<EventEmission[]>([]);
  const { data } = useEventsALLQuery();
  useEffect(() => {
    if (data) {
      setEvents(data?.data?.data);
    }
  }, [data]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function createData(
    id: string,
    name: string,
    subName: string,
    date: string,
    action: React.JSX.Element,
    status: React.JSX.Element,
    statusName: string,
    endDateEvent: string
  ) {
    return { id, name, subName, date, action, status, statusName, endDateEvent };
  }

  function handleClickProject(e: MouseEvent<HTMLElement>) {
    if ((e.target as HTMLInputElement).innerHTML === t('DASHBOARD_PAGE.PROJECT_ACTION.SUCCESS')) {
      let projectID = (e.target as HTMLInputElement).id;
      let listProjectDataStr = sessionStorage.getItem('listProjectData');
      let listProjectData = JSON.parse(listProjectDataStr!);
      let tmpCreateData = listProjectData?.find((item: any) => item.id.toString() === projectID);
      if (tmpCreateData) {
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        setTimeout(() => {
          setOpenSummary(true);
        }, 50);
      }
    } else {
      setIdProjectTarget((e.target as HTMLInputElement).id);
      setOpenPostEvent(true);
    }
  }
  function handleClickCreateProject(e: MouseEvent<HTMLElement>) {
    sessionStorage.setItem('tmpCreateData', '');
    navigate('register/pre/1');
  }
  function handleClickSetting(e: MouseEvent<HTMLElement>) {
    navigate('setting');
  }

;

  const handleClickTab = (keyTab: string) => {
    // const { id } = e.currentTarget;
    setActiveId(keyTab);
    const itemsEvent = data?.data?.data;
    if (keyTab === 'POST_EVENT') {
      const resultFilter = itemsEvent.filter((item: any) => item.isEvent === keyTab);
      setEvents(resultFilter);
    } else if (keyTab === 'PRE_EVENT') {
      const resultFilter = itemsEvent.filter((item: any) => item.isEvent === keyTab);
      setEvents(resultFilter);
    } else if (keyTab === 'COMPENSATED') {
      const resultFilter = itemsEvent.filter((item: any) => item.compensateStatus === keyTab);
      setEvents(resultFilter);
    } else {
      setEvents(itemsEvent);
    }
  };



  function handleClosePostEvent() {
    setIdProjectTarget('');
    setOpenPostEvent(false);
  }

  function handleCloseSummary() {
    setOpenSummary(false);
  }

  function handleDeleteBtn(e: MouseEvent) {}

  function handleClickPreEvent(e: MouseEvent<HTMLElement>) {
    let projectID = idProjectTarget;
    let listProjectDataStr = sessionStorage.getItem('listProjectData');
    let listProjectData = JSON.parse(listProjectDataStr!);
    let tmpCreateData = listProjectData?.find((item: any) => item.id.toString() === projectID);
    if (tmpCreateData) {
      sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
    }
    navigate('register/pre/1');
  }

  function handleClickPostEvent(e: MouseEvent<HTMLElement>) {
    let projectID = idProjectTarget;
    let listProjectDataStr = sessionStorage.getItem('listProjectData');
    let listProjectData = JSON.parse(listProjectDataStr!);
    let tmpCreateData = listProjectData?.find((item: any) => item.id.toString() === projectID);
    tmpCreateData.status = 'post';
    tmpCreateData.paging = 0;
    if (tmpCreateData) {
      sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
    }
    navigate('register/post/0');
  }

  return (
    <div className="container">
      <br />
      <br />
      <div className="columns is-mobile">
        <h1 className="content-header">{t('DASHBOARD_PAGE.HEADER')}</h1>
      </div>
      <div className="columns is-mobile">
        <h3 className="content-sub-header">{t('DASHBOARD_PAGE.SUBHEADER')}</h3>
      </div>
      <div className="columns is-mobile">
        <div className="column is-4 is-offset-6">
          <div className="talk-bubble tri-right round btm-left-in">
            <div className="talktext">
              <p>{t('DASHBOARD_PAGE.CONTENT')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-3 is-offset-1">
          <button
            className="button button-dashboard is-2 is-success is-fullwidth App "
            type="button"
            onClick={handleClickCreateProject}
          >
            {t('BUTTONS.CREATE_PROJECT')}
          </button>
          <br />
          <br />
        </div>
        <div className="column is-4">
          <img src={DashboardLogo} style={{ marginLeft: '40px' }} alt='logo'/>
        </div>
        <div className="column is-3 is-offset-0">
          <button
            className="button button-dashboard is-2 is-fullwidth App"
            style={{ backgroundColor: '#FEC236' }}
            type="button"
            onClick={handleClickSetting}
          >
            {t('BUTTONS.SETTING')}
          </button>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-3 is-offset-1">
          <h1 className="content-header content-left">
            {t('DASHBOARD_PAGE.PROJECT_TABLE.HEADER')}
          </h1>
          <h3 className="content-sub-header content-left">
            {t('DASHBOARD_PAGE.PROJECT_TABLE.SUBHEADER')}
          </h3>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-10 is-offset-1 box">
          <div className="tabs is-fullwidth">
            <ul>
              <li className={activeTabId === 'all' ? 'is-active' : ''}>
                <a onClick={() => handleClickTab('all')}>
                  <span>{t('DASHBOARD_PAGE.TABS.TAB1')}</span>
                </a>
              </li>
              <li className={activeTabId === 'PRE_EVENT' ? 'is-active' : ''}>
                <a onClick={() => handleClickTab('PRE_EVENT')}>
                  <span>{t('DASHBOARD_PAGE.TABS.TAB2')}</span>
                </a>
              </li>
              <li className={activeTabId === 'POST_EVENT' ? 'is-active' : ''}>
                <a onClick={() => handleClickTab('POST_EVENT')}>
                  <span>{t('DASHBOARD_PAGE.TABS.TAB3')}</span>
                </a>
              </li>
              <li className={activeTabId === 'COMPENSATED' ? 'is-active' : ''}>
                <a onClick={() => handleClickTab('COMPENSATED')}>
                  <span>{t('DASHBOARD_PAGE.TABS.TAB4')}</span>
                </a>
              </li>
            </ul>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell className="content-left">#</StyledTableCell>
                  <StyledTableCell className="content-left">
                    {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_NAME')}
                  </StyledTableCell>
                  <StyledTableCell className="content">
                    {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_DATE')}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="content-right">
                    {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_STATUS')}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="content-center">
                    {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_ACTION')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((row: EventEmission, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell component="th" scope="row" className="content-left">
                      <div>
                        <p className="content-left">
                          <b>{row.name}</b>
                          {/* {isDateLessThanCurrent(row.createdAt) && row.statusName === 'pre' ? (
                            <CircleIcon
                              sx={{
                                color: red[700],
                                marginTop: '-3px',
                                width: '16px',
                                marginLeft: '10px',
                              }}
                            />
                          ) : (
                            ''
                          )} */}
                        </p>
                        <p className="content-left">{row?.location}</p>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="content">
                      {formatDateEn(row.startDate)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ display: 'flex', justifyContent: 'end' }}>
                      <div className="flex justify-between max-w-60 items-center">
                        <button
                          className="button shadow-sm is-rounded is-fullwidth is-small is-success font-semibold
                          hover:shadow-lg transition-shadow duration-150  "
                          style={
                            row.compensateStatus !== 'COMPENSATED'
                              ? { backgroundColor: '#FEC236', color: 'black' }
                              : {
                                  cursor: 'unset',
                                }
                          }
                        >
                          {row.compensateStatus}
                        </button>
                        <CircleIcon
                          className="blink"
                          sx={{
                            color:
                              row.compensateStatus !== 'COMPENSATED' ? yellow[700] : green[400],
                            marginLeft: '10px',
                            // animation: 'blinker 3s linear infinite',
                          }}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <button className=" is-small" onClick={handleDeleteBtn} type="button">
                        <Iconify icon="eva:trash-2-outline" />
                      </button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Dialog
        open={openPostEvent}
        onClose={handleClosePostEvent}
        fullWidth
        maxWidth="xs"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '15px', // Adjust the value as needed
          },
        }}
      >
        <DialogContent dividers={false}>
          <div className="column is-12">
            <h4 className="content-center">{t('DASHBOARD_PAGE.POST_EVENT_DIALOG.CONTENT')}</h4>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="column">
            <div className="buttons" style={{ justifyContent: 'center' }}>
              <button
                className="button App"
                type="button"
                onClick={handleClickPreEvent}
                style={{ backgroundColor: '#FEC236' }}
              >
                {t('DASHBOARD_PAGE.POST_EVENT_DIALOG.PRE_EVENT')}
              </button>
              <button
                className="button is-success App"
                type="submit"
                onClick={handleClickPostEvent}
              >
                {t('DASHBOARD_PAGE.POST_EVENT_DIALOG.POST_EVENT')}
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <SummaryGHG open={openSummary} handleClose={handleCloseSummary} />
    </div>
  );
}
