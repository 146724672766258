import React from 'react';

interface ProtectedRouteProps {
  component: React.ComponentType;
  allowedRoles: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedRoles,
  ...rest
}) => {
  return <Component />;
};

export default ProtectedRoute;
