import React, { useState, useEffect, RefCallback, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useAccept, useGetAccept } from '@src/services/queries/useAuthQuery';
import { UserPolicyAcceptance } from '@src/component/layout/admin/common/types';
import { removeCookie } from '@src/utils/remove-cookie';
import { config } from '@src/config';
import { getCookieName } from '@src/utils/cookie-name';
import Swal from 'sweetalert2';

interface propsPDPA {
  open: boolean;
  refPage: string;
  token?: string | undefined;
  handleClose: RefCallback<any>;
}

export default function PDPA(props: propsPDPA) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { open, refPage, handleClose } = props;
  const [isDisableSubmitBtn, setIsDisableSubmitBtn] = useState(true);
  const [selectedValuePDPA1, setSelectedValuePDPA1] = useState('');
  const [selectedValuePDPA2, setSelectedValuePDPA2] = useState('');
  const [selectedValuePDPA3, setSelectedValuePDPA3] = useState('');

  const { mutateAsync } = useAccept();
  const useGetAcceptQuery = useGetAccept();
  const { data } = useGetAcceptQuery;

  const autoSetValue = () => {
    if (data?.data && data?.data.length > 0) {
      setSelectedValuePDPA1(data?.data[0].acception ? 'accept' : 'reject');
      setSelectedValuePDPA2(data?.data[1].acception ? 'accept' : 'reject');
      setSelectedValuePDPA3(data?.data[2].acception ? 'accept' : 'reject');
    }
  };

  useEffect(() => {
    if (selectedValuePDPA1 && selectedValuePDPA2 && selectedValuePDPA3) {
      setIsDisableSubmitBtn(false);
    } else {
      setIsDisableSubmitBtn(true);
    }
  }, [selectedValuePDPA1, selectedValuePDPA2, selectedValuePDPA3]);

  function handleCancel(e: MouseEvent) {
    if (refPage === 'login') {
      //Cancel to login
      navigate('/');
      handleClose('login');
      removeCookie(config.keyNameCookie);
    } else {
      handleClose('pdpaSetting');
      autoSetValue();
    }
  }

  async function handleSubmit(e: MouseEvent<HTMLFormElement>) {
    //coding for submit pdpa here
    e.preventDefault();
    const dataAccept: UserPolicyAcceptance = [
      {
        policyId: 1,
        acception: selectedValuePDPA1 === 'accept' ? true : false,
      },
      {
        policyId: 2,
        acception: selectedValuePDPA2 === 'accept' ? true : false,
      },
      {
        policyId: 3,
        acception: selectedValuePDPA3 === 'accept' ? true : false,
      },
    ];
    try {
      const result = await mutateAsync({
        token: props.token ? props.token : getCookieName(config.keyNameCookie),
        accept: dataAccept,
      });
      if (result.status === 201) {
        handleClose('pdpaSetting');

        Swal.fire({
          icon: 'success',
          title:
            i18n.language === 'en'
              ? 'Accept PDPA successfully'
              : i18n.language === 'th'
                ? 'ยอมรับ PDPA สำเร็จ'
                : '成功接受 PDPA',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (getCookieName(config.keyNameCookie) && location.pathname === '/setting') {
            window.location.href = '/setting';
          } else {
            window.location.href = '/dashboard';
          }
        });
      }
      console.log(result.status);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (open && location.pathname === '/setting') {
      setTimeout(() => {
        autoSetValue();
      }, 100);
    }
  }, [open, location.pathname]);
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px', // Adjust the value as needed
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent dividers={false} style={{ overflow: 'hidden' }}>
          <div className="detail-scroll-dialog p-4">
            <div className="columns content-center">
              <div className="column is-10">
                <h3 className="content-sub-header font-bold ">
                  {t('SETTING_PAGE.PDPA_DIAG.HEADER')}
                </h3>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <p className="content">{t('SETTING_PAGE.PDPA_DIAG.CONTENT1')}</p>
                  <p className="content">{t('SETTING_PAGE.PDPA_DIAG.CONTENT2')}</p>
                  <p className="content">{t('SETTING_PAGE.PDPA_DIAG.PDPA1')}</p>
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setSelectedValuePDPA1(e.target.value)}
                  defaultValue="accept"
                  className="content-center"
                  value={selectedValuePDPA1}
                  style={{ marginBottom: '25px' }}
                >
                  <FormControlLabel
                    value="accept"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.ACCEPT_BTN')}</p>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.REJECT_BTN')}</p>
                    }
                  />
                </RadioGroup>
                <div className="content">
                  <p>{t('SETTING_PAGE.PDPA_DIAG.PDPA2')}</p>
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setSelectedValuePDPA2(e.target.value)}
                  defaultValue="accept"
                  className="content-center"
                  value={selectedValuePDPA2}
                  style={{ marginBottom: '25px' }}
                >
                  <FormControlLabel
                    value="accept"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.ACCEPT_BTN')}</p>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.REJECT_BTN')}</p>
                    }
                  />
                </RadioGroup>
                <div className="content">
                  <p>{t('SETTING_PAGE.PDPA_DIAG.PDPA3')}</p>
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setSelectedValuePDPA3(e.target.value)}
                  defaultValue="accept"
                  className="content-center"
                  value={selectedValuePDPA3}
                  style={{ marginBottom: '25px' }}
                >
                  <FormControlLabel
                    value="accept"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.ACCEPT_BTN')}</p>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.REJECT_BTN')}</p>
                    }
                  />
                </RadioGroup>
                <div className="content">
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT3')}</p>
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT4')}</p>
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT5')}</p>
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT6')}</p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="column">
            <div className="buttons" style={{ justifyContent: 'center' }}>
              <button
                className="button is-rounded is-danger App"
                type="button"
                onClick={handleCancel}
              >
                {t('BUTTONS.CANCEL')}
              </button>
              <button
                className="button is-rounded is-info App"
                type="submit"
                disabled={isDisableSubmitBtn}
              >
                {t('BUTTONS.SAVE')}
              </button>
            </div>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
