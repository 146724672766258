import DashboardIcon from "@mui/icons-material/Dashboard";
import { ReactNode } from "react";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
interface nav {
  icon: ReactNode;
  title: string;
  path: string;
}
const navConfig: nav[] = [
  {
    title: "ภาพรวม",
    path: "/admin",
    icon: <DashboardIcon />,
  },
  {
    title: "คำขอกิจกรรมประเภท Pre-event",
    path: "/admin/pre-event",
    icon: <AssignmentOutlinedIcon />,
  },
  {
    title: "คำขอกิจกรรมประเภท Post-event",
    path: "/admin/post-event",
    icon: <AssignmentOutlinedIcon />,
  },
  {
    title: "โครงการทั้งหมด",
    path: "/admin/project",
    icon: <AssessmentOutlinedIcon />,
  },
  {
    title: "ผู้ใช้งาน",
    path: "/admin/users",
    icon: <SettingsOutlinedIcon />,
  },
];

export default navConfig;
