import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';

// ----------------------------------------------------------------------
type TableRowZeroCarbonProp = {
  index: number;
  rows: any;
  handleClick?: (amg: any) => void;
  selected?: any;
  defaultChecked?: boolean;
  TableRowBody?: React.ReactNode;
};
export default function TableRowZeroCarbon({
  index,
  rows,
  selected,
  handleClick,
  defaultChecked = false,
  TableRowBody,
}: TableRowZeroCarbonProp) {
  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        role="checkbox"
        style={{
          height: '2.75rem',
        }}
      >
        {defaultChecked && (
          <TableCell padding="checkbox">
            <Checkbox
              disableRipple
              sx={{ width: 12, p: 0 }}
              checked={selected}
              onChange={handleClick}
            />
          </TableCell>
        )}
        {TableRowBody}
      </TableRow>
    </>
  );
}
