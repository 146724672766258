import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rawEmission from './rawData.json';
import CardEmission from './ElementEmission/CardEmission';
import { findIconByName, getNameByLanguage } from './UtilEmission';
import EmissionItem from './ElementEmission/EmissionItem';
import TravelEmission from './ElementEmission/TypeEmission/TravelEmission';
import SpecifyEmissionsDialog from './ElementEmission/SpecifyEmissionsDialog';
import Iconify from '@src/component/layout/admin/components/iconify';
import FoodEmission from './ElementEmission/TypeEmission/FoodEmission';
import AccommodationEmission from './ElementEmission/TypeEmission/AccommodationEmission';
import WasteEmission from './ElementEmission/TypeEmission/WasteEmission';
import ElectricEmission from './ElementEmission/TypeEmission/ElectricEmission';
import TransportEmission from './ElementEmission/TypeEmission/TransportEmission';
import { useForm } from 'react-hook-form';
import { CardEmissionForm } from './ElementEmission/CardEmissionForm';
import { EmissionRunTimeTypeInput } from './ElementEmission/EmissionRunTimeTypeInput';
const GasEmissions = () => {
    const { t, i18n } = useTranslation();

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm();

    const [emissionSources, setEmissionSources] = useState<any>([]);
    const [openSpecifyEmissions, setOpenSpecifyEmissions] = useState<boolean>(false);
    const [specifyEmissionsIndex, setSpecifyEmissionsIndex] = useState<number | undefined>();
    const [activeSpecifyEmissions, setActiveSpecifyEmissions] = useState<number | undefined>();

    useEffect(() => {
        if (rawEmission) {
            setEmissionSources(rawEmission.emissions_sources);
        }
    }, []);

    const handleChangeSwitch = (index: number) => {
        const newEmissionSources = [...emissionSources];
        newEmissionSources[index].default = !newEmissionSources[index].default;

        // clear state index
        if (newEmissionSources[index].default && activeSpecifyEmissions === index) {
            setActiveSpecifyEmissions(undefined);

            // clear specifyEmissions default
        }

        setEmissionSources(newEmissionSources);
    };

    const handleSpecifyByIndex = (index: number) => {
        setActiveSpecifyEmissions(index);
        setSpecifyEmissionsIndex(undefined);
    };

    const handleUpdateSpecifyByIndex = (index: number) => {
        setSpecifyEmissionsIndex(index);
        setValueSpecifyEmissions(index);
    };

    const updateEmissions = (index: number, newData: Partial<any>) => {
        const updatedEmissionSources = emissionSources.map((source: any, sourceIndex: number) => {
            if (sourceIndex !== activeSpecifyEmissions) return source;
            console.log(newData);
            const updatedSpecifyEmissions = source.specify_emissions.map((emission: any, emissionIndex: number) =>
                emissionIndex === index ? { ...emission, ...newData } : emission,
            );

            return { ...source, specify_emissions: updatedSpecifyEmissions };
        });

        setEmissionSources(updatedEmissionSources);
    };

    const handleRemoveSpecify = (index: number) => {
        updateEmissions(index, {
            enable: !emissionSources[activeSpecifyEmissions as number].specify_emissions[index].enable,
        });

        setSpecifyEmissionsIndex(undefined);
    };

    const handleSubmitSpecify = (data: any) => {
        console.log(data);
        const { runtime_type } = emissionSources[activeSpecifyEmissions as number].specify_emissions[specifyEmissionsIndex as number];
        // if (runtime_type === 'TravelEmission') {
        //     updateEmissions(specifyEmissionsIndex as number, data);
        // } else if (runtime_type === 'FoodEmission') {
        //     updateEmissions(specifyEmissionsIndex as number, data);
        // } else if (runtime_type === 'AccommodationEmission') {
        //     updateEmissions(specifyEmissionsIndex as number, data);
        // }

        updateEmissions(specifyEmissionsIndex as number, data);
        reset();
        // close log index specify
        setSpecifyEmissionsIndex(undefined);
    };

    const handleSubmitAndNext = () => {
        console.table(emissionSources);
    };

    const setValueSpecifyEmissions = (index: number) => {
        // Get the active emission source and the specified emission
        const activeSource = emissionSources[activeSpecifyEmissions as number];
        const emission = activeSource.specify_emissions[index];

        // Check the runtime type
        if (emission.runtime_type === 'TravelEmission') {
            setValue('passengers_total', emission.passengers_total);
            setValue('distance_outbound_total', emission.distance_outbound_total);
        }

        if (emission.runtime_type === 'AccommodationEmission') {
            setValue('guests', emission.guests);
            setValue('night', emission.night);
        }

        if (emission.runtime_type === 'FoodEmission') {
            setValue('set', emission.set);
        }
        console.log(emission.runtime_type);
        if (emission.runtime_type === 'ElectricEmission' || emission.runtime_type === 'ElectricOnLineEmission') {
            console.log(emission.kw_hour);
            setValue('kw_hour', emission.kw_hour);
        }

        if (emission.runtime_type === 'WasteEmission' || emission.runtime_type === 'GiftEmission') {
            setValue('weight', emission.weight);
        }
    };

    const emissionSourcesEnable = useMemo(() => {
        if (activeSpecifyEmissions !== undefined) {
            const specifyEmissionsTotal = emissionSources[activeSpecifyEmissions].specify_emissions.length;
            const enableTotal = emissionSources[activeSpecifyEmissions].specify_emissions.filter((item: any) => item.enable === false);
            return enableTotal.length === specifyEmissionsTotal;
        }
        return false;
    }, [emissionSources, activeSpecifyEmissions]);

    useEffect(() => {
        const handleLanguageChange = () => {
            setTimeout(() => {
                trigger();
            }, 50);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, trigger]);
    return (
        <div className="container">
            <div className="columns is-mobile">
                <div className="column  mt-12">
                    <h1 className="content-header content-left font-medium text-black">{t('DETAIL_PAGE.HEADER')}</h1>
                </div>
            </div>
            <div className="columns ">
                <div className="column  is-full-mobile is-half-tablet is-one-third-desktop">
                    <div className="flex justify-between">
                        <h4 className="content-left font-semibold text-black">{t('DETAIL_PAGE.SOURCE_CO2E')}</h4>
                        <h4 className="content-left font-semibold text-black">{t('DETAIL_PAGE.DEFAULT_FLAG')}</h4>
                    </div>

                    <div className="mt-4">
                        {emissionSources.map((item: any, index: number) => (
                            <EmissionItem
                                key={index}
                                item={item}
                                index={index}
                                handleSpecify={() => handleSpecifyByIndex(index)}
                                handleChangeSwitch={() => handleChangeSwitch(index)}
                                language={i18n.language}
                            />
                        ))}
                    </div>
                </div>

                <div className="column is-5 relative">
                    <div className="gird grid-cols-1 overflow-y-auto pt-[5px] max-h-[60vh] p-4 overflow-hidden ">
                        {activeSpecifyEmissions !== undefined &&
                            emissionSources[activeSpecifyEmissions].specify_emissions.map(
                                (specify: any, specifyIndex: number) =>
                                    specify.enable && (
                                        <CardEmission
                                            key={specifyIndex}
                                            title={specify[getNameByLanguage(i18n.language)]}
                                            subTitle={emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                                            onClick={() => handleUpdateSpecifyByIndex(specifyIndex)}
                                            onRemove={() => handleRemoveSpecify(specifyIndex)}
                                            icon={<Iconify icon={findIconByName(specify.name_en)} sx={{ color: '#000' }} width={24} />}
                                        >
                                            {specify.runtime_type === 'TravelEmission' && <TravelEmission {...specify} />}

                                            {specify.runtime_type === 'FoodEmission' && <FoodEmission {...specify} />}

                                            {specify.runtime_type === 'AccommodationEmission' && <AccommodationEmission {...specify} />}

                                            {(specify.runtime_type === 'WasteEmission' || specify.runtime_type === 'GiftEmission') && (
                                                <WasteEmission {...specify} />
                                            )}

                                            {(specify.runtime_type === 'ElectricEmission' || specify.runtime_type === 'ElectricOnLineEmission') && (
                                                <ElectricEmission {...specify} />
                                            )}

                                            {specify.runtime_type === 'TransportEmission' && <TransportEmission {...specify} />}
                                        </CardEmission>
                                    ),
                            )}
                    </div>
                    {emissionSourcesEnable && (
                        <div className="flex justify-center py-3">
                            <h1 className="content-header-s">{t('DETAIL_PAGE.HEADER_CUSTOM_EVENT')}</h1>
                        </div>
                    )}
                    {activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions].specify_emissions.length > 0 && (
                        <div className="flex justify-center py-3">
                            <button className="button is-rounded is-info" type="button" onClick={() => setOpenSpecifyEmissions(true)}>
                                {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                            </button>
                        </div>
                    )}
                </div>
                <div className="column is-full-mobile is-half-tablet is-one-third-desktop">
                    <form onSubmit={handleSubmit(handleSubmitSpecify)}>
                        {activeSpecifyEmissions !== undefined &&
                            specifyEmissionsIndex !== undefined &&
                            emissionSources[activeSpecifyEmissions].specify_emissions.map(
                                (specifyForm: any, spxIndex: number) =>
                                    spxIndex === specifyEmissionsIndex && (
                                        <div key={spxIndex}>
                                            <CardEmissionForm
                                                subTitle={emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                                                typeName={findIconByName(specifyForm.name_en)}
                                                title={specifyForm[getNameByLanguage(i18n.language)]}
                                            >
                                                <EmissionRunTimeTypeInput runtime_type={specifyForm.runtime_type} register={register} errors={errors} />

                                                <button className="button is-rounded is-info my-4" type="submit">
                                                    {t('BUTTONS.SAVE')}
                                                </button>
                                            </CardEmissionForm>
                                        </div>
                                    ),
                            )}
                    </form>
                </div>
            </div>

            <div className="flex justify-center">
                <button className="column button is-rounded is-large max-w-[400px] is-success " onClick={handleSubmitAndNext}>
                    {t('BUTTONS.NEXT')}
                </button>
            </div>

            <SpecifyEmissionsDialog
                language={i18n.language}
                onClose={() => setOpenSpecifyEmissions(false)}
                open={openSpecifyEmissions}
                title={activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                subTitle={activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                specifyEmissions={activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions].specify_emissions}
                onRemove={handleRemoveSpecify}
            />
        </div>
    );
};

export default GasEmissions;
