export function getNameByLanguage(language: string): string {
    switch (language) {
        case 'en':
            return 'name_en';
        case 'cn':
            return 'name_ch';
        default:
            return 'name';
    }
}

export function findIconByName(name: string): string {
    const icons = [
        {
            name: 'Domestic Flight',
            icon: 'mdi:flight',
        },
        {
            name: 'International Flight',
            icon: 'mdi:flight',
        },
        {
            name: 'Personal Car',
            icon: 'ph:car',
        },
        {
            name: 'Personal Pickup truck',
            icon: 'ph:car',
        },
        {
            name: 'Personal Electric Vehicle',
            icon: 'material-symbols:electric-car',
        },
        {
            name: 'Personal Van',
            icon: 'ph:van',
        },
        {
            name: 'Taxi',
            icon: 'mdi:taxi',
        },
        {
            name: 'Motorcycle',
            icon: 'ph:motorcycle-bold',
        },
        {
            name: 'Bus',
            icon: 'mdi:bus',
        },
        {
            name: 'Passenger Van',
            icon: 'mdi:van-passenger',
        },
        {
            name: 'Bicycle or Walk',
            icon: 'pepicons-pencil:bicycle',
        },
        {
            name: 'Diesel train',
            icon: 'material-symbols:train',
        },
        {
            name: 'Sky train',
            icon: 'material-symbols:train',
        },
        {
            name: 'Private boat',
            icon: 'fontisto:sait-boat',
        },
        {
            name: 'Public boat',
            icon: 'mdi:boat',
        },
        {
            name: 'Food',
            icon: 'mdi:food',
        },
        {
            name: 'General Food',
            icon: 'mdi:food',
        },
        {
            name: 'Vegetarian Food',
            icon: 'hugeicons:vegetarian-food',
        },
        {
            name: 'General snack & beverage',
            icon: 'mdi:food',
        },
        {
            name: 'Vegetarian snack & beverage',
            icon: 'mdi:food',
        },
        {
            name: 'Plastic container for food',
            icon: 'mdi:food',
        },
        {
            name: 'Plastic container for Snack & beverage',
            icon: 'cbi:garbage-plastic',
        },

        {
            name: '1 & 2 star hotel',
            icon: 'material-symbols:hotel-outline',
        },
        {
            name: '3 star hotel',
            icon: 'material-symbols:hotel-outline',
        },
        {
            name: '4 star hotel',
            icon: 'material-symbols:hotel-outline',
        },
        {
            name: '5 star hotel',
            icon: 'material-symbols:hotel-outline',
        },
        {
            name: 'Homestay without conditioner',
            icon: 'icon-park-outline:homestay',
        },
        {
            name: 'Homestay with conditioner',
            icon: 'icon-park-outline:homestay',
        },

        {
            name: 'Paper',
            icon: 'mdi:paper',
        },
        {
            name: 'Plastic',
            icon: 'cbi:garbage-plastic',
        },
        {
            name: 'Textile',
            icon: 'icon-park-twotone:clothes-hoodie',
        },
        {
            name: 'Textile',
            icon: 'icon-park-twotone:clothes-hoodie',
        },
        {
            name: 'Food waste',
            icon: 'maki:waste-basket',
        },
        {
            name: 'Wood',
            icon: 'tabler:wood',
        },
        {
            name: 'Other',
            icon: 'maki:waste-basket',
        },
        {
            name: '1 hour post event meter - 1 hour pre event meter',
            icon: 'material-symbols:gas-meter',
        },

        {
            name: 'Truck',
            icon: 'mdi:truck',
        },
        {
            name: '6-Wheeled truck',
            icon: 'fa-solid:truck-moving',
        },
        {
            name: '10-Wheeled truck',
            icon: 'fa-solid:truck-moving',
        },
        {
            name: '18-Wheeled truck',
            icon: 'fa-solid:truck-moving',
        },
        {
            name: 'Vessel without container',
            icon: 'icon-park-solid:ship',
        },
        {
            name: 'Vessel with container',
            icon: 'game-icons:cargo-ship',
        },
        {
            name: 'Cargo aircraft',
            icon: 'entypo:aircraft',
        },
        {
            name: 'Metal',
            icon: 'game-icons:metal-bar',
        },
    ];
    const result = icons.find(icon => icon.name === name);

    return result ? result.icon : 'mdi:set-not';
}
