import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { zodResolver } from '@hookform/resolvers/zod';

import { UserCreate } from '@src/types';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateUser, useUpdateUser } from '@src/services/queries/useUsersQuery';
import Swal from 'sweetalert2';
import {
  UserFormInput,
  userFormSchema,
  userUpdateFormSchema,
} from '@src/validators/userFormSchema';
interface FormUserProps {
  open: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
  defaultValue?: UserCreate | undefined;
}

export const FormUser: FC<FormUserProps> = ({ open, setOpenForm, handleClose, defaultValue }) => {
  const [showPassword, setShowPassword] = useState(false);

  const QueryCreateUser = useCreateUser();
  const QueryUpdateUser = useUpdateUser();

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UserFormInput>({
    resolver: zodResolver(defaultValue ? userUpdateFormSchema : userFormSchema),
  });

  const handleCreateUser = async (user: UserFormInput) => {
    try {
      await QueryCreateUser.mutateAsync(user);
      setOpenForm(false);

      Swal.fire({
        title: 'สร้างสําเร็จ',
        text: 'เพิ่มข้อมูลสําเร็จ',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          popup: 'popup-backend',
        },
      }).then(({ isDismissed }) => {
        if (isDismissed) {
          reset();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateUser = async (user: UserFormInput) => {
    try {
      const sendData = {
        ...user,
        id: defaultValue?.id,
      };
      QueryUpdateUser.mutateAsync(sendData);
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = (data: UserFormInput) => {
    console.log(data);
    if (defaultValue) {
      handleUpdateUser(data);
    } else {
      handleCreateUser(data);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue('firstName', defaultValue?.firstName);
      setValue('lastName', defaultValue?.lastName);
      setValue('email', defaultValue?.email);
      setValue('tel', defaultValue?.tel);
      setValue('password', defaultValue?.password);
      setValue('role', defaultValue?.role ?? '');
    }
  }, [defaultValue]);

  return (
    <Dialog fullWidth maxWidth={'md'} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {defaultValue ? `แก้ไขข้อมูล ผู้ใช้งาน` : 'สร้างข้อมูล ผู้ใช้งาน'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <label>
                ชื่อ <span className="text-red-600">*</span>
              </label>
              <TextField
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                fullWidth
                size="small"
                InputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label>
                นามสกุล <span className="text-red-600">*</span>
              </label>
              <TextField
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
                size="small"
                InputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label>
                เบอร์โทร <span className="text-red-600">*</span>
              </label>
              <TextField
                size="small"
                {...register('tel')}
                error={!!errors.tel}
                helperText={errors.tel?.message}
                fullWidth
                inputProps={{
                  maxLength: 10,
                }}
                InputProps={{ sx: { borderRadius: 1.6, backgroundColor: 'white' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <label>
                สิทธิการใช้งาน <span className="text-red-600">*</span>
              </label>
              <FormControl fullWidth size="small" error={!!errors.role?.message}>
                <Select
                  id="role"
                  label="สิทธิการใช้งาน"
                  {...register('role')}
                  error={!!errors.role}
                  defaultValue={defaultValue?.role ?? ''}
                >
                  <MenuItem value="ADMIN">ผู้ดูแลระบบ</MenuItem>
                  <MenuItem value="MEMBER">บุคคลทั่วไป</MenuItem>
                </Select>

                {errors.role && <FormHelperText>{errors.role?.message}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label>
                อีเมล <span className="text-red-600">*</span>
              </label>
              <TextField
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                size="small"
                InputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label>
                รหัสผ่าน <span className="text-red-600">*</span>
              </label>
              <TextField
                type={showPassword ? 'text' : 'password'}
                fullWidth
                {...register('password')}
                error={!!errors.password}
                helperText={errors.password?.message}
                size="small"
                InputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }}
            variant="contained"
          >
            <CloseTwoToneIcon />
            <Box pt={0.4}> ปิดหน้าต่าง</Box>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color={defaultValue ? 'secondary' : 'primary'}
            sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }}
          >
            {defaultValue ? (
              <>
                <EditTwoToneIcon />
                <Box pt={0.4}> ยันยันแก้ไข</Box>
              </>
            ) : (
              <>
                <CheckCircleTwoToneIcon />
                <Box pt={0.4}> ยันยัน</Box>
              </>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
