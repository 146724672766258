export const backgroundColor = [
  'rgba(255, 0, 0, 0.314)',
  'rgba(255, 127, 0, 0.314)',
  'rgba(255, 255, 0, 0.314)',
  'rgba(0, 255, 0, 0.314)',
  'rgba(0, 0, 255, 0.314)',
  'rgba(75, 0, 130, 0.314)',
  'rgba(148, 0, 211, 0.314)',
  'rgba(255, 0, 255, 0.314)',
];

export const BorderColors = [
  'rgba(255, 0, 0, 0.7)',
  'rgba(255, 127, 0, 0.7)',
  'rgba(255, 255, 0, 0.7)',
  'rgba(0, 255, 0, 0.7)',
  'rgba(0, 0, 255, 0.7)',
  'rgba(75, 0, 130, 0.7)',
  'rgba(148, 0, 211, 0.7)',
  'rgba(255, 0, 255, 0.7)',
];
