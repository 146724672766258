import { StyleSheet } from '@react-pdf/renderer';

const Style = StyleSheet.create({
  page: {
    position: 'relative',
    // width: '100%',
    // height: '100%',
  },
  pageBackground: {
    position: 'absolute',
    height: '99.9%',
    width: '100%',
  },

  textTitleContainer: {
    // position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 290,
    // top: 290,
  },
  BoxContainer: {
    // backgroundColor: 'red',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContent: {
    fontSize: 14,
    fontWeight: 200,
    wordWrap: 'break-word',
    fontFamily: 'THSan',
    color: '#03346E',
    textAlign: 'center',
  },
  textDescContainer: {
    position: 'absolute',
    top: 370,
  },
  textDescOne: {
    fontSize: 9,
    fontFamily: 'THSan',
    marginLeft: 45,
    paddingLeft: 30,
    marginTop: 4,
  },
  textDescTwo: {
    fontSize: 9,
    fontFamily: 'THSan',
    marginLeft: 45,
    paddingLeft: 10,
  },

  textDescThree: {
    fontSize: 9,
    fontFamily: 'THSan',
    marginLeft: 45,
    paddingLeft: 10,
  },

  positionCredit: {
    position: 'absolute',
    width: '100%',
    bottom: 5,
    left: 20,
  },
  textCredit: {
    fontSize: 10,
    fontWeight: 200,
    fontFamily: 'THSan',
  },
});
export default Style;
