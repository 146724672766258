import { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';

const Iconify = forwardRef(
  (
    {
      icon,
      width = 20,
      sx,
      ...other
    }: {
      icon: string;
      width?: number;
      sx?: any;
      other?: any;
    },
    ref
  ) => (
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
);

export default Iconify;
