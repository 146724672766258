import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Mice from './mice';
import Form from './form';
import Detail from './detail';
import Summary from './summary';
import DownloadLogo from './downloadLogo';
import Upload from './upload';
import Payment from './payment';
import Certificate from './certificate';
import Attachment from './attachment';
import ProjectOffset from './projectOffset';
import './register.css';

export default function Register() {
  const { id, event } = useParams();
  const [registerPagingNo, setRegisterPagingNo] = useState<number>(1);
  const [registerStatus, setRegisterStatus] = useState<string>('');
  const navigate = useNavigate();
  let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
  let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
  let pageLanding = tmpCreateData?.paging;
  let projectStatus = tmpCreateData?.status;
  let listPageHasBackground = [0, 4, 5, 6, 7, 8, 9];
  useEffect(() => {
    if ((pageLanding < parseInt(id!) || projectStatus !== event) && pageLanding && projectStatus) {
      setRegisterStatus(projectStatus);
      setRegisterPagingNo(pageLanding);
      navigate('/register/' + projectStatus + '/' + pageLanding);
    } else {
      setRegisterStatus(event!);
      setRegisterPagingNo(parseInt(id!));
    }
  }, [id, event]);

  return (
    <div
      className={
        listPageHasBackground.includes(registerPagingNo) ? 'is-background' : 'is-none-background'
      }
    >
      <div className="container">
        {registerPagingNo === 1 && registerStatus === 'pre' ? <Mice /> : ''}
        {registerPagingNo === 2 ? <Form /> : ''}
        {registerPagingNo === 3 ? <Detail /> : ''}
        {registerPagingNo === 4 || registerPagingNo === 0 ? <Summary /> : ''}
        {registerPagingNo === 5 && registerStatus === 'pre' ? <DownloadLogo /> : ''}
        {registerPagingNo === 5 && registerStatus === 'post' ? <Upload /> : ''}
        {registerPagingNo === 6 && registerStatus === 'post' ? <Attachment /> : ''}
        {registerPagingNo === 7 && registerStatus === 'post' ? <ProjectOffset /> : ''}
        {registerPagingNo === 8 && registerStatus === 'post' ? <Payment /> : ''}
        {registerPagingNo === 9 && registerStatus === 'post' ? <Certificate /> : ''}
      </div>
    </div>
  );
}
