import { Icon } from '@iconify/react';
import React from 'react';

const PageError = () => {
  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4  sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <Icon icon="ic:baseline-error" className="mx-auto h-20 w-20 text-red-500" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          เกิดข้อผิดพลาด
        </h1>
        <p className="mt-4 text-muted-foreground">
          กรุณาเปิดแอพลิเคชันอีกครั้ง หรือ ติดต่อผู้ดูแลระบบ
        </p>
        <div className="mt-6"></div>
      </div>
    </div>
  );
};

export default PageError;
