import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import Header from './admin/header';
import Nav from './admin/nav';
import Main from './admin/main';
import { createTheme, ThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: `"Kanit", sans-serif`,
  },
});
const AdminLayout: React.FC = () => {
  const [openNav, setOpenNav] = useState(false);
  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={defaultTheme}>
          <Header onOpenNav={() => setOpenNav(true)} />
          <Box
            sx={{
              minHeight: 1,
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
            }}
          >
            <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

            <Main children={<Outlet />} sx={undefined}></Main>
          </Box>
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
};

export default AdminLayout;
