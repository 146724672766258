import React from 'react';
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Setting from '../pages/Setting';
import EmissionsTest from '@src/pages/Register/GasEmissions';

export default function Routes() {
  return (
    <ReactRoutes>
      <Route path="/login" element={<Login />} />;
      <Route path="/" element={<Dashboard />} />;
      <Route path="/register/:event/:id" element={<Register />} />;
      <Route path="/setting" element={<Setting />} />;
      <Route path="/emissions-test" element={<EmissionsTest />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </ReactRoutes>
  );
}
