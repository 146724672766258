import React from 'react';
import PinIcon from '@mui/icons-material/Pin';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
interface CreditDialogProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: any) => void;
  defaultValue?: string;
}
const schema = z.object({
  creditNumber: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
});
export type FormCreditData = z.infer<typeof schema>;
const CreditDialog: React.FC<CreditDialogProps> = ({
  open,
  handleClose,
  onSubmit,
  defaultValue,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormCreditData>({
    resolver: zodResolver(schema),
    defaultValues: {
      creditNumber: defaultValue,
    },
  });

  const onSubmitForm = async (data: FormCreditData) => {
    await onSubmit(data);

    // handleClose();
    // register('creditNumber', { value: '' });
  };
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        {' '}
        <PinIcon />
        {'แก้ไขเลขที่เครดิต'}{' '}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <TextField
            label="เลขที่เครดิต"
            {...register('creditNumber')}
            error={!!errors.creditNumber}
            helperText={errors.creditNumber?.message}
            fullWidth
            margin="normal"
            inputProps={{ borderRadius: '8px' }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ borderRadius: '8px', alignItems: 'center' }}
            variant="contained"
          >
            <CloseIcon />
            ปิดหน้าต่าง
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              color: 'black',
              backgroundColor: '#B4E380',
              '&:hover': {
                backgroundColor: '#88D66C',
              },
              borderRadius: '8px',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <ThumbUpIcon />
            ยันยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreditDialog;
