import { getCookieName } from '@src/utils/cookie-name';
import axios from 'axios';

const token = getCookieName('zero-carbon-auth.session-token');
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

export default api;
