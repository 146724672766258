import Iconify from '@src/component/layout/admin/components/iconify';
import React, { ReactNode } from 'react';

export const CardEmissionForm = ({
    title,
    subTitle,
    typeName,
    children,
}: {
    children: ReactNode;

    title?: string;
    subTitle?: string;
    typeName?: string;
}) => {
    return (
        <div className="bg-white shadow-xl rounded-xl p-2   relative cursor-pointer">
            <div className="w-full flex justify-between items-center">
                <div className="flex">
                    <div className="bg-gray-300 w-9 h-9 rounded-full flex items-center justify-center shadow-2xl">
                        {typeName && <Iconify icon={typeName} sx={{ fontSize: 18, color: '#000' }} width={20} />}
                    </div>
                    <div className="ml-3 text-left">
                        <div className="text-black">{title}</div>
                        <div className="text-sm text-gray-500">{subTitle}</div>
                    </div>
                </div>
            </div>
            <div className="mt-4 px-4">{children}</div>
        </div>
    );
};
