export const DetailCarbonItem: Record<string, Record<string, string>> = {
  setTransporter: {
    detailDomedtically: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_DOMEDTICALLY',
    detailInternational: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_INTERNATIONAL',
    detailCar: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_CAR',
    detailElectrictCar: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_ELECTRICT_CAR',
    detailPickup: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_PICKUP',
    detailVan: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_VAN',
    detailTexi: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_TEXI',
    detailMoto: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_MOTO',
    detailBus: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_BUS',
    detailVanPub: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_VAN_PUB',
    detailBicycle: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_BICYCLE',
    detailTrainDiesel: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_TRAIN_DIESEL',
    detailElectricTrain: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_ELECTRIC_TRAIN',
    detailBoat: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_BOAT',
    detailBoatPub: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DETAIL_BOAT_PUB',
  },
  setFoodDrink: {
    detailMainCourse: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.DETAIL_MAIN_COURSE',
    detailMainCourseVeget: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.DETAIL_MAIN_COURSE_VEGET',
    detailSnack: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.DETAIL_SNACK',
    detailSnackVeget: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.DETAIL_SNACK_VEGET',
  },
  setRestPlace: {
    detailHotel1n2: 'DETAIL_PAGE.REST_PLACE_DETAIL.DETAIL_HOTEL1N2',
    detailHotel3: 'DETAIL_PAGE.REST_PLACE_DETAIL.DETAIL_HOTEL3',
    detailHotel4: 'DETAIL_PAGE.REST_PLACE_DETAIL.DETAIL_HOTEL4',
    detailHotel5: 'DETAIL_PAGE.REST_PLACE_DETAIL.DETAIL_HOTEL5',
    detailHomestay: 'DETAIL_PAGE.REST_PLACE_DETAIL.DETAIL_HOMESTAY',
    detailHomestayAir: 'DETAIL_PAGE.REST_PLACE_DETAIL.DETAIL_HOMESTAY_AIR',
  },
  setWaste: {
    detailGarbageContainerWaste: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_GARBAGE_CONTAINER_WASTE',
    detailPaperWaste: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_PAPER_WASTE',
    detailPlasticWaste: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_PLASTIC_WASTE',
    detailClothWaste: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_CLOTH_WASTE',
    detailGarbageWaste: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_GARBAGE_WASTE',
    detailWoodWaste: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_WOOD_WASTE',
    detailOtherWate: 'DETAIL_PAGE.WASTE_DETAIL.DETAIL_OTHER_WATE',
  },
  setElectricSite: {
    detailEnergyUsageSite: 'DETAIL_PAGE.ELECTRIC_SITE_DETAIL.DETAIL_ENERGY_USAGE_SITE',
  },
  setElectric: {
    detailEnergyUsageSite: 'DETAIL_PAGE.ELECTRIC_DETAIL.DETAIL_ENERGY_USAGE_SITE',
  },
  setGift: {
    detailPaperGift: 'DETAIL_PAGE.GIFT_DETAIL.DETAIL_PAPER_GIFT',
    detailPlasticGift: 'DETAIL_PAGE.GIFT_DETAIL.DETAIL_PLASTIC_GIFT',
    detailMetalGift: 'DETAIL_PAGE.GIFT_DETAIL.DETAIL_METAL_GIFT',
    detailClothGift: 'DETAIL_PAGE.GIFT_DETAIL.DETAIL_CLOTH_GIFT',
    detailWoodGift: 'DETAIL_PAGE.GIFT_DETAIL.DETAIL_WOOD_GIFT',
    detailOtherGift: 'DETAIL_PAGE.GIFT_DETAIL.DETAIL_OTHER_GIFT',
  },
  setExpress: {
    detailTruck4: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_TRUCK4',
    detailTruck6: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_TRUCK6',
    detailTruck10: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_TRUCK10',
    detailTruck18: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_TRUCK18',
    detailCargoShip: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_CARGO_SHIP',
    detailCargoContainerShip: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_CARGO_CONTAINER_SHIP',
    detailCargoPlane: 'DETAIL_PAGE.EXPRESS_DETAIL.DETAIL_CARGO_PLANE',
  },
};

export const DetailCarbonItemEF: Record<string, Record<string, number>> = {
  setTransporter: {
    detailDomedtically: 0.1733,
    detailInternational: 0.1143,
    detailCar: 0.1622,
    detailElectrictCar: 0.0756,
    detailPickup: 0.2466,
    detailVan: 0.2686,
    detailTexi: 0.1893,
    detailMoto: 0.0579,
    detailBus: 0.03,
    detailVanPub: 0.0269,
    detailBicycle: 0,
    detailTrainDiesel: 0.0536,
    detailElectricTrain: 0.7102,
    detailBoat: 0.07,
    detailBoatPub: 0.0018,
  },
  setFoodDrink: {
    detailMainCourse: 3.93,
    detailMainCourseVeget: 1.12,
    detailSnack: 0.217,
    detailSnackVeget: 0.1464,
    detailFoodPlastic: 0.131,
    detailDrinkPlastic: 0.1596,
  },
  setRestPlace: {
    detailHotel1n2: 6.08,
    detailHotel3: 8.1,
    detailHotel4: 9.14,
    detailHotel5: 14.21,
    detailHomestay: 0.28,
    detailHomestayAir: 3.13,
  },
  setWaste: {
    detailGarbageContainerWaste: 2.53,
    detailPaperWaste: 2.93,
    detailPlasticWaste: 2.32,
    detailClothWaste: 2,
    detailGarbageWaste: 2.53,
    detailWoodWaste: 3.33,
    detailOtherWate: 2.32,
  },
  setElectricSite: {
    detailEnergyUsageSite: 0.4999,
  },
  setElectric: {
    detailEnergyUsageSite: 0.4999,
  },
  setGift: {
    detailPaperGift: 2.1639,
    detailPlasticGift: 6.7071,
    detailMetalGift: 6.3369,
    detailClothGift: 11.5,
    detailWoodGift: 0.3841,
    detailOtherGift: 11.5,
  },
  setExpress: {
    detailTruck4: 0.2313,
    detailTruck6: 0.0945,
    detailTruck10: 0.0813,
    detailTruck18: 0.0706,
    detailCargoShip: 0.0056,
    detailCargoContainerShip: 0.0107,
    detailCargoPlane: 1.49,
  },
};

export const CarbonCategory: Record<string, string> = {
  setTransporter: 'DETAIL_PAGE.EVENT_TYPE.TRANSPORTER',
  setFoodDrink: 'DETAIL_PAGE.EVENT_TYPE.FOOD_DRINK',
  setRestPlace: 'DETAIL_PAGE.EVENT_TYPE.REST_PLACE',
  setWaste: 'DETAIL_PAGE.EVENT_TYPE.WASTE',
  setElectricSite: 'DETAIL_PAGE.EVENT_TYPE.ELECTRIC_SITE',
  setElectric: 'DETAIL_PAGE.EVENT_TYPE.ELECTRIC',
  setGift: 'DETAIL_PAGE.EVENT_TYPE.GIFT',
  setExpress: 'DETAIL_PAGE.EVENT_TYPE.EXPRESS',
};

export const customerEventMapper: Record<string, any> = {
  detailDomedtically: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailDomedticallyUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailDomedticallyIcon: 'fa-plane',
  detailInternational: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailInternationalUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailInternationalIcon: 'fa-plane',
  detailCar: ['DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT', 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT'],
  detailCarUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailCarIcon: 'fa-regular fa-car',
  detailElectrictCar: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailElectrictCarUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailElectrictCarIcon: 'fa-car',
  detailPickup: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailPickupUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailPickupIcon: 'fa-regular fa-car',
  detailVan: ['DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT', 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT'],
  detailVanUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailVanIcon: 'fa-regular fa-car',
  detailTexi: ['DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT', 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT'],
  detailTexiUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailTexiIcon: 'fa-regular fa-car',
  detailMoto: ['DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT', 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT'],
  detailMotoUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailMotoIcon: 'fa-regular fa-car',
  detailBus: ['DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT', 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT'],
  detailBusUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailBusIcon: 'fa-car',
  detailVanPub: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailVanPubUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailVanPubIcon: 'fa-regular fa-car',
  detailBicycle: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailBicycleUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailBicycleIcon: 'fa-bicycle',
  detailTrainDiesel: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailTrainDieselUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailTrainDieselIcon: 'fa-train',
  detailElectricTrain: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailElectricTrainUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailElectricTrainIcon: 'fa-train',
  detailBoat: ['DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT', 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT'],
  detailBoatUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailBoatIcon: 'fa-plane',
  detailBoatPub: [
    'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT',
    'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT',
  ],
  detailBoatPubUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailBoatPubIcon: 'fa-regular fa-sailboat',

  detailMainCourse: ['DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT'],
  detailMainCourseUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailMainCourseIcon: 'fa-burger',
  detailMainCourseVeget: ['DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT'],
  detailMainCourseVegetUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailMainCourseVegetIcon: 'fa-burger',
  detailSnack: ['DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT'],
  detailSnackUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailSnackIcon: 'fa-martini-glass-citrus',
  detailSnackVeget: ['DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT'],
  detailSnackVegetUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailSnackVegetIcon: 'fa-martini-glass-citrus',
  detailFoodPlastic: ['DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT'],
  detailFoodPlasticUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailFoodPlasticIcon: 'fa-burger',
  detailDrinkPlastic: ['DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT'],
  detailDrinkPlasticUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailDrinkPlasticIcon: 'fa-martini-glass-citrus',

  detailHotel1n2: [
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON',
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT',
  ],
  detailHotel1n2Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT', stepInput: '1' },
    { id: 'Night', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailHotel1n2Icon: 'fa-bed',
  detailHotel3: [
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON',
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT',
  ],
  detailHotel3Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT', stepInput: '1' },
    { id: 'Night', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailHotel3Icon: 'fa-bed',
  detailHotel4: [
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON',
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT',
  ],
  detailHotel4Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT', stepInput: '1' },
    { id: 'Night', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailHotel4Icon: 'fa-bed',
  detailHotel5: [
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON',
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT',
  ],
  detailHotel5Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT', stepInput: '1' },
    { id: 'Night', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailHotel5Icon: 'fa-bed',
  detailHomestay: [
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON',
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT',
  ],
  detailHomestayUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT', stepInput: '1' },
    { id: 'Night', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailHomestayIcon: 'fa-bed',
  detailHomestayAir: [
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON',
    'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT',
  ],
  detailHomestayAirUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT', stepInput: '1' },
    { id: 'Night', name: 'DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailHomestayAirIcon: 'fa-bed',

  detailGarbageContainerWaste: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailGarbageContainerWasteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailGarbageContainerWasteIcon: 'fa-dumpster',
  detailPaperWaste: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailPaperWasteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailPaperWasteIcon: 'fa-dumpster',
  detailPlasticWaste: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailPlasticWasteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailPlasticWasteIcon: 'fa-dumpster',
  detailClothWaste: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailClothWasteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailClothWasteIcon: 'fa-dumpster',
  detailGarbageWaste: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailGarbageWasteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailGarbageWasteIcon: 'fa-dumpster',
  detailWoodWaste: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailWoodWasteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailWoodWasteIcon: 'fa-dumpster',
  detailOtherWate: ['DETAIL_PAGE.WASTE_DETAIL.AMOUNT'],
  detailOtherWateUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailOtherWateIcon: 'fa-dumpster',

  detailEnergyUsageSite: ['DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT'],
  detailEnergyUsageSiteUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailEnergyUsageSiteIcon: 'fa-bolt',
  detailEnergyUsage: ['DETAIL_PAGE.ELECTRIC_DETAIL.AMOUNT'],
  detailEnergyUsageUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.ELECTRIC_DETAIL.AMOUNT_UNIT', stepInput: '1' },
  ],
  detailEnergyUsageIcon: 'fa-bolt',

  detailPaperGift: ['DETAIL_PAGE.GIFT_DETAIL.AMOUNT'],
  detailPaperGiftUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.GIFT_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailPaperGiftIcon: 'fa-gift',
  detailPlasticGift: ['DETAIL_PAGE.GIFT_DETAIL.AMOUNT'],
  detailPlasticGiftUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.GIFT_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailPlasticGiftIcon: 'fa-gift',
  detailMetalGift: ['DETAIL_PAGE.GIFT_DETAIL.AMOUNT'],
  detailMetalGiftUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.GIFT_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailMetalGiftIcon: 'fa-gift',
  detailClothGift: ['DETAIL_PAGE.GIFT_DETAIL.AMOUNT'],
  detailClothGiftUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.GIFT_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailClothGiftIcon: 'fa-gift',
  detailWoodGift: ['DETAIL_PAGE.GIFT_DETAIL.AMOUNT'],
  detailWoodGiftUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.GIFT_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailWoodGiftIcon: 'fa-gift',
  detailOtherGift: ['DETAIL_PAGE.GIFT_DETAIL.AMOUNT'],
  detailOtherGiftUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.GIFT_DETAIL.AMOUNT_UNIT', stepInput: '0.01' },
  ],
  detailOtherGiftIcon: 'fa-gift',

  detailTruck4: ['DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT', 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT'],
  detailTruck4Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailTruck4Icon: 'fa-truck-front',
  detailTruck6: ['DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT', 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT'],
  detailTruck6Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailTruck6Icon: 'fa-truck-front',
  detailTruck10: ['DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT', 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT'],
  detailTruck10Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailTruck10Icon: 'fa-truck-front',
  detailTruck18: ['DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT', 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT'],
  detailTruck18Unit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailTruck18Icon: 'fa-truck-front',
  detailCargoShip: ['DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT', 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT'],
  detailCargoShipUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailCargoShipIcon: 'fa-ship',
  detailCargoContainerShip: [
    'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT',
    'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT',
  ],
  detailCargoContainerShipUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailCargoContainerShipIcon: 'fa-ship',
  detailCargoPlane: ['DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT', 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT'],
  detailCargoPlaneUnit: [
    { id: 'Amount', name: 'DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT', stepInput: '1' },
    { id: 'Distance', name: 'DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT', stepInput: '0.01' },
  ],
  detailCargoPlaneIcon: 'fa-plane-up',
};
