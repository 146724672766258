import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { handleError } from '../queries';
import { UserFormInput } from '@src/validators/userFormSchema';
import api from '../axios';

export const useUsers = (authType: string, page: number, limit: number) => {
  return useQuery({
    queryKey: ['users', authType, page, limit],
    queryFn: async ({ queryKey }: { queryKey: [string, string, number, number] }) => {
      const [, authType, page, limit] = queryKey;
      try {
        const { data } = await api.get(`/user?authType=${authType}&page=${page}&limit=${limit}`);
        return data;
      } catch (error) {
        throw error;
      }
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ['users'] });
  };

  return useMutation({
    mutationFn: async (user: UserFormInput) => {
      try {
        const { data } = await api.post('/user', user);
        return data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const handleSuccess = async (data: any) => {
    await queryClient.invalidateQueries({ queryKey: ['users'] });
    await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
  };

  return useMutation({
    mutationFn: async (user: UserFormInput) => {
      try {
        const { data } = await api.patch(`/user/${user.id}`, user);
        return data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ['users'] });
  };
  return useMutation({
    mutationFn: async (id: number | undefined) => {
      try {
        const { data } = await api.delete(`/user/${id}`);
        return data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export const useUserById = (id: string) => {
  return useQuery({
    queryKey: ['userId', id],
    queryFn: async ({ queryKey }: { queryKey: [string, string] }) => {
      const [, id] = queryKey;
      try {
        const { data } = await api.get(`/user/${id}`);
        return data;
      } catch (error) {
        throw error;
      }
    },
  });
};

export const useUserResetPassword = () => {
  return useMutation({
    mutationFn: async (user: { email: string; password: string; confirmPassword: string }) => {
      try {
        const { data } = await api.post(`/user/reset-password`, user);
        return data;
      } catch (error) {
        throw error;
      }
    },
    onError: handleError,
  });
};
