export function optionYear() {
  const startYear = 2566;
  const currentYear = new Date().getFullYear() + 543; // Convert to Buddhist year
  let arrYear = [];
  for (let year = startYear; year <= currentYear; year++) {
    const yearAD = year - 543;
    arrYear.push({ label: year.toString(), value: yearAD.toString() });
  }

  return arrYear;
}
