import React, { useState } from 'react';
import { emptyRows, applyFilter, getComparator } from '@admin/components/TableZeroCarbon/utils';
import Scrollbar from '@src/component/scrollbar';
import {
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';
import TableHeadZero from '@admin/components/TableZeroCarbon/TableHead';
import TableEmptyRows from '@admin/components/TableZeroCarbon/TableEmptyRows';
import TableToolBar from '@admin/components/TableZeroCarbon/TableToolBar';
import { useNavigate } from 'react-router';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TableRowZeroCarbon from '../../../components/TableZeroCarbon/TableRow';
import { formatDate } from '@src/utils/format-date-th';
import { usePreEventsQuery } from '@src/services/queries/useEventsQuery';
import TableNoData from '../../../components/TableZeroCarbon/TableNoData';
import TableLoading from '../../../components/TableZeroCarbon/TableLoading';
import { EventEmission } from '@src/types';
import CompensateStatusBadge from '../../../components/Element/CompensateStatusBadge';

interface PreEventTableProp {
  filterSelect: {
    projectType: string;
    years: string;
    quarter: string;
  };
}
const PreEventTable = ({ filterSelect }: PreEventTableProp) => {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [search, setSearch] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const { data, isLoading } = usePreEventsQuery(
    filterSelect.projectType,
    filterSelect.years,
    filterSelect.quarter,
    search,
    page + 1,
    rowsPerPage
  );

  const navigate = useNavigate();

  const handleSort = (event: any, id: any) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilter = (query: string) => {
    setPage(0);
    setSearch(query);
  };

  const preEvents = data?.data || [];

  const dataFiltered = applyFilter({
    inputData: preEvents,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!search;

  const handleView = (id: number | undefined) => {
    navigate(`/admin/pre-event/${id}`);
  };

  const TableRowBody = ({ index, rows }: { index: number; rows: EventEmission }) => {
    const { id, name, location, startDate, compensateStatus, emission, endDate } = rows;
    return (
      <>
        {' '}
        <TableCell
          sx={{
            textAlign: 'center',
          }}
        >
          {index + 1}
        </TableCell>
        <TableCell scope="row" className="text-xs whitespace-nowrap text-left">
          <div className="truncate max-w-72 flex flex-col" title={name}>
            {name}
            <span className="text-gray-500 font-light">{location}</span>
          </div>
        </TableCell>
        <TableCell className="text-xs">
          <div>{emission?.activity_type?.name}</div>
        </TableCell>
        <TableCell className="text-xs">
          <div className="text-center">
            {' '}
            {emission?.summary_person ? emission?.summary_person.toFixed(2) : 0}{' '}
          </div>
        </TableCell>
        <TableCell className="text-xs">
          <div className="text-center">{emission?.summary ? emission?.summary.toFixed(2) : 0}</div>
        </TableCell>
        <TableCell className="text-xs">
          {formatDate(startDate)}
          {' - '}
          {formatDate(endDate)}
        </TableCell>
        <TableCell align="center">
          <div className="flex justify-center">
            <CompensateStatusBadge compensateStatus={compensateStatus} />
          </div>
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="delete" size="small">
            <VisibilityOutlinedIcon onClick={() => handleView(id)} />
          </IconButton>
        </TableCell>
      </>
    );
  };
  return (
    <>
      <TableToolBar onSearch={handleFilter} />
      <Scrollbar>
        <Card>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: {
                  sm: 1700,
                  md: 1600,
                  lg: 1300,
                },
              }}
              size={'small'}
            >
              <colgroup>
                <col width="1%" />
                <col width="18%" />
                <col width="10%" />
                <col width="14%" />
                <col width="19%" />
                <col width="16%" />
                <col width="10%" />
                <col width="7%" />
              </colgroup>
              <TableHeadZero
                order={order}
                orderBy={orderBy}
                rowCount={dataFiltered.length}
                onRequestSort={handleSort}
                headLabel={[
                  { id: 'id', label: 'ลำดับ', align: 'center' },
                  { id: 'activityName', label: 'ชื่อกิจกรรม', align: 'left' },
                  { id: 'activityType', label: 'ประเภทกิจกรรม', align: 'left' },
                  { id: 'participants', label: 'จำนวนผู้ร่วม (คน/วัน)', align: 'center' },
                  {
                    id: 'carbonFootprint',
                    label: ' ปริมาณคาร์บอนฟุตพริ้นท์รวม (ตัน)	',
                    align: 'left',
                  },
                  { id: 'eventDate', label: 'วันที่จัดกรรม', align: 'center' },
                  { id: 'status', label: 'สถานะ', align: 'center' },
                  { id: 'tools', label: 'เครื่องมือ' },
                ]}
              />
              <TableBody sx={{ fontSize: '0.75rem' }}>
                {isLoading && <TableLoading />}

                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRowZeroCarbon
                      key={index}
                      rows={row}
                      index={index}
                      TableRowBody={<TableRowBody index={index} rows={row} />}
                    />
                  ))}

                <TableEmptyRows
                  height={notFound ? 2 : 5}
                  emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)}
                />

                {notFound && <TableNoData query={search} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Scrollbar>

      <TablePagination
        count={data?.total || 0}
        onPageChange={handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PreEventTable;
