import { formatNumber } from '@src/utils/format-number';
import React from 'react';
import { useTranslation } from 'react-i18next';

function WasteEmission({ weight, ef }: { weight: number; ef: number }) {
    const { t } = useTranslation();

    const totalCarbonFootprint = (weight || 0) * (ef || 0);

    return (
        <div className="mt-4">
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.WASTE_DETAIL.AMOUNT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(weight ?? 0, 0)}</span>
                    {t('DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT')}
                </div>
            </div>

            <div className="flex justify-between items-center font-semibold">
                <div className=" text-black"> {t('DETAIL_PAGE.CARBON_FOOTPRINT')}</div>
                <div className=" text-black">{totalCarbonFootprint ? totalCarbonFootprint?.toFixed(2) : 0} kgCO2eq</div>
            </div>
        </div>
    );
}

export default WasteEmission;
