import { ProjectCreate } from '@src/types';
import api from './axios';

export const fetchUsers = async () => {
  try {
    const { data } = await api.get('/user?authType=CREDENTIAL');
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjects = async ({ queryKey }: { queryKey: [string, number, number] }) => {
  const [, page, limit] = queryKey;
  try {
    const { data } = await api.get(`/projects?page=${page}&limit=${limit}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createProject = async (project: ProjectCreate) => {
  try {
    const { data } = await api.post('/projects', project);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateProject = async (id: number | undefined, project: ProjectCreate) => {
  try {
    const { data } = await api.patch(`/projects/${id}`, project);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (id: number) => {
  try {
    const { data } = await api.delete(`/projects/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
