import { IconButton } from '@mui/material';
import Iconify from '@src/component/layout/admin/components/iconify';
import React, { ReactElement } from 'react';

function CardEmission({
    onClick,
    children,
    onRemove,
    title,
    subTitle,
    icon,
}: {
    title: string;
    subTitle: string;
    onClick?: () => void;
    onRemove?: () => void;
    children: React.ReactNode;
    icon?: ReactElement;
}) {
    return (
        <div className="bg-white shadow-xl rounded-xl p-4  my-4 relative cursor-pointer">
            <div className="absolute -top-3 -right-2">
                <IconButton onClick={onRemove}>
                    <Iconify icon="clarity:remove-solid" />
                </IconButton>
            </div>
            <div className="w-full flex justify-between items-center" onClick={onClick}>
                <div className="flex">
                    <div className="bg-gray-300 w-9 h-9 rounded-full flex items-center justify-center shadow-2xl">
                        {icon ? icon : <Iconify icon="ion:airplane-sharp" sx={{ fontSize: 18, color: '#000' }} width={20} />}
                    </div>
                    <div className="ml-3 text-left">
                        <div className="text-black">{title}</div>
                        <div className="text-sm text-gray-500">{subTitle}</div>
                    </div>
                </div>
                <IconButton onClick={onClick}>
                    <Iconify icon="bytesize:ellipsis-vertical" sx={{ fontSize: 18 }} width={24} />
                </IconButton>
            </div>
            {children}
        </div>
    );
}

export default CardEmission;
