import React, { useEffect, useState, useRef, ChangeEvent, MouseEvent } from 'react';
import { Switch, Icon, IconButton } from '@mui/material';
import { eventType } from '../../constants/screen/eventType';
import { DetailCarbonItemEF, customerEventMapper, DetailCarbonItem, CarbonCategory } from '../../constants/screen/detailEvent';
import { useParams, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ErrorNotify } from '../../constants/screen/toast';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { formatterNumber, calculateEqCo2Standard, getDecimalDigit, formatterNumberDigit } from '../../constants/screen/util';
import i18n from '@src/i18n';
import { blockInvalidChar } from '@src/constants/screen/util';
import CancelIcon from '@mui/icons-material/Cancel';

type transportDataVar = {
    amount: number;
    distance: number;
    round: number;
};

export default function RegisterDetail() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id, event } = useParams();
    const [eventTarget, setEventTarget] = useState<any>({});
    const [eventDetailType, setEventDetailType] = useState<any>({});
    const [eventDetail, setEventDetail] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [eventDetailSelected, setEventDetailSelected] = useState([]);
    const [eventDetailTarget, setEventDetailTarget] = useState<any>({});
    const [transportAmount, setTransportAmount] = useState(1);
    const [transportData, setTransportData] = useState<transportDataVar[]>([]);
    const [triggerTransportForm, setTriggerTransportForm] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm<any>();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const descriptionElementRef = useRef<HTMLInputElement>(null);

    const handleDetailEventSelected = (event: any, detailEvent: any) => {
        let tmpCreateDataSessionStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateDataSession = tmpCreateDataSessionStr ? JSON.parse(tmpCreateDataSessionStr) : '';
        let eventDetailSession = tmpCreateDataSession.eventDetail;
        if (detailEvent.length) {
            eventDetailSelected.map(item => {
                if (!detailEvent.includes(item)) {
                    delete eventDetailSession?.[item];
                    if (eventDetail.id === item) {
                        setEventDetail({});
                    }
                }
            });
            let newEvent: any = {};
            detailEvent.map((item: string) => {
                newEvent[item] = {
                    type: 'custom',
                    eventCategory: eventDetailType.name,
                };
            });
            let newEventDetail = { ...newEvent };
            tmpCreateDataSession.eventDetail = Object.assign(newEventDetail, eventDetailSession);
            sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateDataSession));
            setEventDetailTarget(eventDetailSession);
            setEventDetailSelected(detailEvent);
        }
    };

    const handleSaveDetailEvent: SubmitHandler<any> = e => {
        let errorFlag = false;
        let objData: any = {};
        let EFValue = DetailCarbonItemEF?.[eventDetailType.name]?.[eventDetail.id];
        if (eventDetailType.name === 'setTransporter') {
            let Co2eq = 1;
            customerEventMapper?.[eventDetail.id]?.map((itemCustom: any, index: number) => {
                let elementID = eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id;
                let elementTarget = document.getElementById(elementID) as HTMLInputElement;
                if (!document.getElementById(elementID) || !elementTarget.value) {
                    ErrorNotify("Please fill in '" + t(itemCustom) + "'");
                    errorFlag = true;
                } else {
                    Co2eq = Co2eq * parseFloat(elementTarget.value);
                    objData[elementID] = parseFloat(elementTarget.value);
                }
            });
            let totalCo2eq = Co2eq * EFValue;
            objData.totalCo2eq = totalCo2eq;
            objData.type = 'custom';
            objData.eventCategory = eventDetailType.name;
        } else if (eventDetailType.name === 'setExpress') {
            let totalCo2eq = 1;
            let weightExpress = 0;
            let distanceExpress = 0;
            let weightExpressID = '';
            let distanceExpressID = '';
            for (var i = 0; i < transportAmount; i++) {
                let roundExpress = i + 1;
                let Co2eqPRound = 1;
                customerEventMapper?.[eventDetail.id]?.map((itemCustom: any, index: any) => {
                    let elementID = eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id + i;
                    let elementTarget = document.getElementById(elementID) as HTMLInputElement;
                    if (!document.getElementById(elementID) || !elementTarget.value) {
                        ErrorNotify("Please fill in '" + t(itemCustom) + "' in round " + roundExpress);
                        errorFlag = true;
                    } else {
                        Co2eqPRound = Co2eqPRound * parseFloat(elementTarget.value);
                        objData[elementID] = parseFloat(elementTarget.value);
                        if (elementID.includes('Amount')) {
                            weightExpress += parseFloat(elementTarget.value);
                            weightExpressID = eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id;
                        } else if (elementID.includes('Distance')) {
                            distanceExpress += parseFloat(elementTarget.value);
                            distanceExpressID = eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id;
                        }
                    }
                });
                totalCo2eq += Co2eqPRound;
            }
            objData.totalCo2eq = totalCo2eq * EFValue;
            objData[weightExpressID] = weightExpress;
            objData[distanceExpressID] = distanceExpress;
            objData.roundAmount = transportAmount;
            objData.type = 'custom';
            objData.eventCategory = eventDetailType.name;
        } else {
            let totalCo2eq = 1;
            customerEventMapper?.[eventDetail.id]?.map((itemCustom: any, index: any) => {
                let elementID = eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id;
                let elementTarget = document.getElementById(elementID) as HTMLInputElement;
                if (!document.getElementById(elementID) || !elementTarget.value) {
                    ErrorNotify("Please fill in '" + t(itemCustom) + "'");
                    errorFlag = true;
                } else {
                    totalCo2eq = totalCo2eq * parseFloat(elementTarget.value);
                    objData[elementID] = parseFloat(elementTarget.value);
                }
            });
            objData.totalCo2eq = totalCo2eq * EFValue;
            objData.type = 'custom';
            objData.eventCategory = eventDetailType.name;
        }
        if (!errorFlag) {
            objData = {
                eventDetail: {
                    [eventDetail.id]: objData,
                },
            };
            let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
            let tmpCreateData = JSON.parse(tmpCreateDataStr!);
            if (tmpCreateData.eventDetail) {
                objData.eventDetail = Object.assign(tmpCreateData.eventDetail, objData.eventDetail);
            }
            tmpCreateData = Object.assign(tmpCreateData, objData);
            sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
            setEventDetailTarget(objData.eventDetail);
            setEventDetail({});
        }
    };

    useEffect(() => {
        const handleLanguageChange = () => {
            setTimeout(() => {
                trigger(); // Re-validate the form to update error messages
            }, 50);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, trigger]);

    useEffect(() => {
        let initObjEventType = {};
        let eventDetailSessionStr = sessionStorage.getItem('tmpCreateData');
        let eventDetailSessionMaster = eventDetailSessionStr ? JSON.parse(eventDetailSessionStr) : {};
        let eventDetailSession = eventDetailSessionStr ? JSON.parse(eventDetailSessionStr).eventDetail : {};
        let keyDetailEvent = eventDetailSession ? Object.keys(eventDetailSession) : [];

        eventType?.map(item => {
            let indexCustom = keyDetailEvent.findIndex(
                itemFind => eventDetailSession[itemFind].eventCategory === item.id && eventDetailSession[itemFind].type === 'custom',
            );
            if (indexCustom > -1) {
                Object.assign(initObjEventType, { [item.id]: false });
            } else {
                Object.assign(initObjEventType, { [item.id]: true });
            }
        });
        setEventTarget(eventDetailSession?.eventTarget ? eventDetailSession?.eventTarget : initObjEventType);
        setEventDetailTarget(eventDetailSession);
    }, []);

    useEffect(() => {
        if (open) {
            if (descriptionElementRef.current !== null) {
                descriptionElementRef.current.focus();
            }
        }
    }, [open]);

    function handleEventType(e: ChangeEvent<HTMLInputElement>) {
        let checkedStatus = e.target.checked;
        if (e.target.checked && e.target.id === eventDetailType.name) {
            setEventDetailType({});
            setEventDetail({});
        }
        if (checkedStatus) {
            let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
            let tmpCreateDataMaster = JSON.parse(tmpCreateDataStr!);
            let tmpCreateData = tmpCreateDataMaster.eventDetail;
            let keyEventDetail = tmpCreateData ? Object.keys(tmpCreateData) : [];
            keyEventDetail.map(key => {
                if (tmpCreateData[key].eventCategory === e.target.id && tmpCreateData[key].type === 'custom') {
                    delete tmpCreateData[key];
                }
            });
            tmpCreateDataMaster.eventDetail = tmpCreateData;
            tmpCreateDataMaster.eventDetail.eventTarget = {
                ...eventTarget,
                [e.target.id]: checkedStatus,
            };
            sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateDataMaster));
        }
        if (!checkedStatus) {
            let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
            let tmpCreateDataMaster = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
            let tmpCreateData = tmpCreateDataMaster.eventDetail;
            let keyEventDetail = tmpCreateData ? Object.keys(tmpCreateData) : [];
            keyEventDetail.map(key => {
                if (tmpCreateData[key].eventCategory === e.target.id && tmpCreateData[key].type === 'standard') {
                    delete tmpCreateData[key];
                }
            });
            if (e.target.id === 'setExpress') {
                delete tmpCreateDataMaster.weightExpressStandard;
                delete tmpCreateDataMaster.distanceExpressStandard;
            }
            tmpCreateDataMaster.eventDetail = tmpCreateData;
            if (tmpCreateDataMaster?.eventDetail?.eventTarget) {
                tmpCreateDataMaster.eventDetail.eventTarget = {
                    ...eventTarget,
                    [e.target.id]: checkedStatus,
                };
            } else {
                tmpCreateDataMaster.eventDetail = {
                    eventTarget: { ...eventTarget, [e.target.id]: checkedStatus },
                };
            }
            sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateDataMaster));
        }
        setEventTarget({ ...eventTarget, [e.target.id]: checkedStatus });
    }
    function handleNotStandardEvent(e: MouseEvent) {
        let listDetailEventInit: any = [];
        const { name, id } = e.target as HTMLButtonElement;
        if (eventDetailTarget) {
            Object.keys(eventDetailTarget)?.map(item => {
                if (name === eventDetailTarget?.[item]?.eventCategory && eventDetailTarget?.[item]?.type === 'custom') {
                    listDetailEventInit.push(item);
                }
            });
        }
        setEventDetail({});
        setEventDetailSelected(listDetailEventInit);
        setEventDetailType({ id: id, name: name });
    }

    function handleEventDetail(e: MouseEvent) {
        reset();
        let elementTarget = e.target as HTMLButtonElement;
        let obj = {
            id: elementTarget.id,
            name: elementTarget.name,
        };

        if (eventDetailType.name === 'setExpress') {
            let arrData = [];
            let roundAmount = 1;
            if (eventDetailTarget?.[obj.id]?.roundAmount) {
                roundAmount = eventDetailTarget[obj.id].roundAmount;
            }
            for (let i = 0; i < roundAmount; i++) {
                let objData: transportDataVar = {
                    amount: eventDetailTarget?.[obj.id]?.[obj.id + customerEventMapper?.[obj.id + 'Unit']?.[0]?.id + i],
                    distance: eventDetailTarget?.[obj.id]?.[obj.id + customerEventMapper?.[obj.id + 'Unit']?.[1]?.id + i],
                    round: i + 1,
                };
                arrData.push(objData);
            }
            setTransportData(arrData);
            setTransportAmount(roundAmount);
        }
        setEventDetail(obj);
        if (eventDetailTarget?.[obj.id]) {
            Object.keys(eventDetailTarget[obj.id]).map(item => {
                setValue(item, eventDetailTarget[obj.id][item]);
            });
        }
    }

    function handleSubmitNext(e: MouseEvent) {
        e.preventDefault();
        let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateData = JSON.parse(tmpCreateDataStr!);
        let listEventTypeKey = eventTarget ? Object.keys(eventTarget) : [];
        tmpCreateData.eventDetail = tmpCreateData.eventDetail ? tmpCreateData.eventDetail : {};
        listEventTypeKey.map(item => {
            if (eventTarget[item]) {
                tmpCreateData.eventDetail[item + 'Standard'] = calculateEqCo2Standard(item);
            }
        });
        tmpCreateData.status = event;
        tmpCreateData.paging = 4;
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        navigate('/register/' + event + '/4');
    }

    function handleClickTransportDetail() {
        let amount = transportAmount + 1;
        let arrData: transportDataVar[] = [...transportData];
        let objData: transportDataVar = {
            amount: 0,
            distance: 0,
            round: amount,
        };
        arrData.push(objData);
        setTransportAmount(amount);
        setTransportData(arrData);
        setTimeout(() => {
            const htmlTargetAmount = document.getElementById(eventDetail.id + 'Amount' + (amount - 1)) as HTMLInputElement;
            htmlTargetAmount.value = '';

            const htmlTargetDistance = document.getElementById(eventDetail.id + 'Distance' + (amount - 1)) as HTMLInputElement;
            htmlTargetDistance.value = '';
        }, 100);
    }

    const removeSelected = (card: string) => {
        let listCard = eventDetailSelected.filter(e => e !== card);
        let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
        let tmpEventData = tmpCreateData.eventDetail;
        if (tmpEventData?.[card]) {
            delete tmpEventData[card];
        }
        tmpCreateData.eventDetail = tmpEventData;
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        setEventDetailSelected(listCard);
        setEventDetail({});
    };

    const removeExpressRound = (round: number) => {
        let listRound = transportData.filter(e => e.round !== round);
        const newListRound = listRound.map((item, index) => {
            return { ...item, round: index + 1 };
        });
        setTransportAmount(newListRound.length);
        setTransportData(newListRound);
        setTimeout(() => {
            newListRound.map((item, index) => {
                const htmlTargetAmount = document.getElementById(eventDetail.id + 'Amount' + index) as HTMLInputElement;
                htmlTargetAmount.value = item.amount > 0 ? item.amount.toString() : '';

                const htmlTargetDistance = document.getElementById(eventDetail.id + 'Distance' + index) as HTMLInputElement;
                htmlTargetDistance.value = item.distance > 0 ? item.distance.toString() : '';
            });
        }, 100);
    };

    return (
        <div>
            <div className="columns is-mobile">
                <div className="column">
                    <br />
                    <br />
                    <h1 className="content-header content-left">{t('DETAIL_PAGE.HEADER')}</h1>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-4">
                    <div className="columns">
                        <div className="column">
                            <div className="columns">
                                <div className="column is-8">
                                    <h4 className="content-left font-bold">{t('DETAIL_PAGE.SOURCE_CO2E')}</h4>
                                </div>
                                <div className="column is-4">
                                    <h4 className="content-left font-bold">{t('DETAIL_PAGE.DEFAULT_FLAG')}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    {eventType?.map(item => {
                        return (
                            <div className="columns " key={item.id}>
                                <div className="column" style={{ marginTop: '-65px' }}>
                                    <div className="columns is-mobile">
                                        <div className="column is-8">
                                            <h4 className="content-left">{t(item.name)}</h4>
                                        </div>
                                        <div className="column is-4">
                                            <Switch
                                                className="content-left"
                                                id={item.id}
                                                color="info"
                                                onChange={handleEventType}
                                                checked={eventTarget[item.id]}
                                            ></Switch>
                                        </div>
                                    </div>
                                    <div className="column is-11">
                                        {!eventTarget[item.id] ? (
                                            <button
                                                className="button is-fullwidth is-info App"
                                                style={{ marginTop: '-40px', marginBottom: '35px' }}
                                                id={item.id + 'Btn'}
                                                name={item.id}
                                                type="button"
                                                onClick={handleNotStandardEvent}
                                            >
                                                <i className="fas fa-sliders"></i> &nbsp;&nbsp;
                                                {t('DETAIL_PAGE.BUTTONS.CUSTOM_EVENT') + t(item.name)}
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {eventDetailType?.name ? (
                    eventDetailSelected.length === 0 ? (
                        <div className="column is-4" style={{ marginBlockStart: '180px' }}>
                            <h1 className="content-header-s">{t('DETAIL_PAGE.HEADER_CUSTOM_EVENT')}</h1>
                            <br />
                            <button className="button is-rounded is-info" type="button" onClick={handleClickOpen}>
                                {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                            </button>
                            <div style={{ height: '40.5vh' }}></div>
                            <div className="columns">
                                <div className="column" style={{ alignItems: 'right' }}>
                                    <br />
                                    <button className="button is-rounded is-large is-fullwidth is-success App" onClick={handleSubmitNext}>
                                        {t('BUTTONS.NEXT')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="column is-4">
                            <div className={eventDetailSelected.length > 2 ? 'detail-scroll' : 'detail'}>
                                {eventDetailSelected.map(item => {
                                    return (
                                        <div className="box">
                                            <div className="columns">
                                                <div className="column is-2">
                                                    <div
                                                        className="box"
                                                        style={{
                                                            backgroundColor: '#E8E5E4',
                                                            height: '40px',
                                                            width: '12',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <i
                                                            className={'fas ' + customerEventMapper?.[item + 'Icon']}
                                                            style={{ fontSize: 17, marginLeft: '-5px' }}
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div className="column is-7">
                                                    <p className="content-left">{t(DetailCarbonItem?.[eventDetailType.name][item])}</p>
                                                    <p className="content-left" style={{ color: '#C6C3C3', fontSize: 14 }}>
                                                        {t(CarbonCategory?.[eventDetailType.name])}
                                                    </p>
                                                </div>
                                                <div className="column is-1">
                                                    <IconButton
                                                        id={item}
                                                        name={t(DetailCarbonItem?.[eventDetailType.name]?.[item])}
                                                        onClick={handleEventDetail}
                                                    >
                                                        <Icon id={item}>
                                                            <i className="fas fa-ellipsis-vertical" id={item}></i>
                                                        </Icon>
                                                    </IconButton>
                                                </div>
                                                <div className="column is-1">
                                                    <IconButton
                                                        id={item + 'Delete'}
                                                        name={t(DetailCarbonItem?.[eventDetailType.name]?.[item]) + 'Delete'}
                                                        onClick={() => removeSelected(item)}
                                                    >
                                                        <CancelIcon className="delete-card" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <br />
                                            {customerEventMapper[item]?.map((itemCustom: any, index: number) => {
                                                return (
                                                    <div className="columns">
                                                        <div className="column is-8" style={{ marginTop: '-20px' }}>
                                                            <p className="content-left">{t(itemCustom)}</p>
                                                        </div>
                                                        <div className="column is-4" style={{ marginTop: '-20px' }}>
                                                            <p className="content-right" id={customerEventMapper[item][index]?.id} style={{ color: 'green' }}>
                                                                {eventDetailTarget?.[item]?.[item + customerEventMapper[item + 'Unit'][index]?.id]
                                                                    ? formatterNumberDigit(
                                                                          getDecimalDigit(customerEventMapper[item + 'Unit'][index]?.stepInput),
                                                                      ).format(eventDetailTarget[item]?.[item + customerEventMapper[item + 'Unit'][index]?.id])
                                                                    : 0}{' '}
                                                                {t(customerEventMapper[item + 'Unit'][index]?.name)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className="columns">
                                                <div className="column is-8" style={{ marginTop: '-20px' }}>
                                                    <p className="content-left font-bold">{t('DETAIL_PAGE.CARBON_FOOTPRINT')}</p>
                                                </div>
                                                <div className="column is-4" style={{ marginTop: '-20px' }}>
                                                    <p className="content-right font-bold" id={eventDetail.id + 'TotalCarbon'}>
                                                        {eventDetailTarget?.[item]?.totalCo2eq
                                                            ? formatterNumber.format(eventDetailTarget[item]?.totalCo2eq)
                                                            : 0.0}{' '}
                                                        {t('DETAIL_PAGE.CARBON_FOOTPRINT_UNIT')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <br />
                            <button className="button is-rounded is-info" type="button" onClick={handleClickOpen}>
                                {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                            </button>
                            <div className="columns">
                                <div className="column " style={{ alignItems: 'right' }}>
                                    <br />
                                    <button className="button is-rounded is-large is-fullwidth is-success App" onClick={handleSubmitNext}>
                                        {t('BUTTONS.NEXT')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="column is-4">
                        <div className="columns">
                            <div className="column " style={{ alignItems: 'right' }}>
                                <div style={{ height: '72.2vh' }}></div>
                                <button className="button is-rounded is-large is-fullwidth is-success App" onClick={handleSubmitNext}>
                                    {t('BUTTONS.NEXT')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {eventDetail.id ? (
                    <div className={eventDetailType?.name === 'setExpress' && i18n.language === 'en' ? 'column is-5' : 'column is-4'}>
                        <form onSubmit={handleSubmit(handleSaveDetailEvent)} action="#">
                            <div className="columns">
                                <div className="column is-2">
                                    <div
                                        className="box"
                                        style={{
                                            backgroundColor: '#E8E5E4',
                                            height: '40px',
                                            width: '12px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <i className={'fas ' + customerEventMapper?.[eventDetail.id + 'Icon']} style={{ fontSize: 17, marginLeft: '-8px' }}></i>
                                    </div>
                                </div>
                                <div className="column is-8">
                                    <p className="content-left">{t(DetailCarbonItem?.[eventDetailType.name]?.[eventDetail.id])}</p>
                                    <p className="content-left" style={{ color: '#C6C3C3', fontSize: 14 }}>
                                        {t(CarbonCategory?.[eventDetailType.name])}
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div>
                                {eventDetailType?.name === 'setExpress' ? (
                                    <div>
                                        <div
                                            className={transportData.length > 6 ? 'detail-scroll-express' : 'detail-express'}
                                            style={{ width: i18n.language === 'en' ? '532px' : '500px' }}
                                        >
                                            {transportData.map((itemData: transportDataVar, indexData: number) => {
                                                return (
                                                    <div className="columns" style={{ width: i18n.language === 'en' ? '570px' : 'auto' }}>
                                                        <div
                                                            className="column is-2"
                                                            style={{
                                                                marginTop: '-10px',
                                                                marginLeft: '10px',
                                                                paddingRight: '10px',
                                                            }}
                                                        >
                                                            <p className="content-left">
                                                                <b>{t('DETAIL_PAGE.EXPRESS_DETAIL.ROUND_EXPRESS') + itemData.round}</b>
                                                            </p>
                                                            {transportData.length > 1 ? (
                                                                <IconButton
                                                                    id={eventDetail.id + itemData.round}
                                                                    style={{ marginRight: '15px' }}
                                                                    onClick={() => removeExpressRound(itemData.round)}
                                                                >
                                                                    <CancelIcon className="delete-card" />
                                                                </IconButton>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                        {customerEventMapper?.[eventDetail.id]?.map((itemCustom: any, index: number) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        i18n.language === 'en'
                                                                            ? (
                                                                                  t(itemCustom) +
                                                                                  ' (' +
                                                                                  t(customerEventMapper?.[eventDetail.id + 'Unit'][index]?.name) +
                                                                                  ')'
                                                                              ).length > 23
                                                                                ? 'column is-5'
                                                                                : 'column is-4'
                                                                            : 'column'
                                                                    }
                                                                >
                                                                    <div className="columns">
                                                                        <div className="column" style={{ marginTop: '-10px' }}>
                                                                            <p className="content-left">
                                                                                {t(itemCustom) +
                                                                                    ' (' +
                                                                                    t(customerEventMapper?.[eventDetail.id + 'Unit'][index]?.name) +
                                                                                    ')'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="columns">
                                                                        <div className="column" style={{ marginTop: '-20px', marginBottom: '-10px' }}>
                                                                            <input
                                                                                className="input border-curve"
                                                                                type="number"
                                                                                id={
                                                                                    eventDetail.id +
                                                                                    customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id +
                                                                                    indexData
                                                                                }
                                                                                step={customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.stepInput}
                                                                                min={customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.stepInput}
                                                                                onKeyDown={blockInvalidChar}
                                                                                style={{
                                                                                    borderRadius: '10px',
                                                                                    borderColor: errors[
                                                                                        eventDetail.id +
                                                                                            customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id +
                                                                                            indexData
                                                                                    ]
                                                                                        ? 'red'
                                                                                        : '',
                                                                                    background: errors[
                                                                                        eventDetail.id +
                                                                                            customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id +
                                                                                            indexData
                                                                                    ]
                                                                                        ? ''
                                                                                        : '',
                                                                                }}
                                                                                {...register(
                                                                                    eventDetail.id +
                                                                                        customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id +
                                                                                        indexData,
                                                                                    {
                                                                                        required:
                                                                                            t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                                                                            t(itemCustom) +
                                                                                            t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                                                                        valueAsNumber: true,
                                                                                        min: {
                                                                                            value: customerEventMapper?.[eventDetail.id + 'Unit']?.[index]
                                                                                                ?.stepInput,
                                                                                            message:
                                                                                                t(itemCustom) +
                                                                                                t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') +
                                                                                                customerEventMapper?.[eventDetail.id + 'Unit']?.[index]
                                                                                                    ?.stepInput,
                                                                                        },
                                                                                    },
                                                                                )}
                                                                            />
                                                                            {errors[
                                                                                eventDetail.id +
                                                                                    customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id +
                                                                                    indexData
                                                                            ] ? (
                                                                                <p className="content-error">{`${errors[eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id + indexData]?.message}`}</p>
                                                                            ) : (
                                                                                <p className="content-error">&nbsp;</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                            {transportData.length <= 6 ? (
                                                <div>
                                                    <button className="button is-rounded is-info" type="button" onClick={handleClickTransportDetail}>
                                                        {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <button
                                                        className="button is-rounded is-info"
                                                        onClick={() => setTriggerTransportForm(!triggerTransportForm)}
                                                        type="submit"
                                                    >
                                                        {t('BUTTONS.SAVE')}
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {transportData.length > 6 ? (
                                            <div>
                                                <button className="button is-rounded is-info" type="button" onClick={handleClickTransportDetail}>
                                                    {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                <button
                                                    className="button is-rounded is-info"
                                                    onClick={() => setTriggerTransportForm(!triggerTransportForm)}
                                                    type="submit"
                                                >
                                                    {t('BUTTONS.SAVE')}
                                                </button>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ) : (
                                    <div style={{ height: '63.5vh' }}>
                                        {customerEventMapper?.[eventDetail.id]?.map((itemCustom: any, index: number) => {
                                            return (
                                                <>
                                                    <div className="columns">
                                                        <div className="column" style={{ marginTop: '-10px' }}>
                                                            {JSON.stringify(itemCustom)}
                                                            <p className="content-left">
                                                                {t(itemCustom) + ' (' + t(customerEventMapper?.[eventDetail.id + 'Unit'][index]?.name) + ')'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column" style={{ marginTop: '-20px' }}>
                                                            <input
                                                                className="input border-curve"
                                                                type="number"
                                                                id={eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id}
                                                                step={customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.stepInput}
                                                                min={customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.stepInput}
                                                                onKeyDown={blockInvalidChar}
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    borderColor: errors[
                                                                        eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id
                                                                    ]
                                                                        ? 'red'
                                                                        : '',
                                                                    background: errors[
                                                                        eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id
                                                                    ]
                                                                        ? ''
                                                                        : '',
                                                                }}
                                                                {...register(eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id, {
                                                                    required:
                                                                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                                                        t(itemCustom) +
                                                                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                                                    valueAsNumber: true,
                                                                    min: {
                                                                        value: customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.stepInput,
                                                                        message:
                                                                            t(itemCustom) +
                                                                            t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') +
                                                                            customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.stepInput,
                                                                    },
                                                                })}
                                                            />
                                                            {errors[eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id] ? (
                                                                <p className="content-error">{`${errors[eventDetail.id + customerEventMapper?.[eventDetail.id + 'Unit']?.[index]?.id]?.message}`}</p>
                                                            ) : (
                                                                <p className="content-error">&nbsp;</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                        <button
                                            className="button is-rounded is-info"
                                            onClick={() => setTriggerTransportForm(!triggerTransportForm)}
                                            type="submit"
                                        >
                                            {t('BUTTONS.SAVE')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="xs"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>
                    <p className="content-center font-bold">{t(CarbonCategory?.[eventDetailType.name])}</p>
                </DialogTitle>
                <p id="scroll-dialog-sub-title" className="content-center" style={{ textAlign: 'center', fontSize: '15px' }}>
                    {t('DETAIL_PAGE.TITLE_ADD_CUSTOM_EVENT_DIALOG')}
                </p>
                <br />
                <DialogContent dividers={false}>
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <ToggleButtonGroup
                            value={eventDetailSelected}
                            onChange={handleDetailEventSelected}
                            aria-label="eventDetailSelected"
                            orientation="vertical"
                            fullWidth
                            size="medium"
                        >
                            {eventDetailType?.name &&
                                Object.keys(DetailCarbonItem?.[eventDetailType.name]).map(item => {
                                    return (
                                        <ToggleButton className="event-list" value={item} aria-label={item} style={{ textAlign: 'left' }}>
                                            <div className="column is-2">
                                                <div
                                                    className="box"
                                                    style={{
                                                        backgroundColor: '#E8E5E4',
                                                        height: '40px',
                                                        width: '11px',
                                                        paddingTop: '10px',
                                                    }}
                                                >
                                                    <i
                                                        className={'fas ' + customerEventMapper?.[item + 'Icon']}
                                                        style={{ fontSize: 16, marginLeft: '-8px' }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="column is-10">
                                                <p className="content-left">{t(DetailCarbonItem?.[eventDetailType.name][item])}</p>
                                                <p className="content-left" style={{ color: '#C6C3C3', fontSize: 14 }}>
                                                    {t(CarbonCategory?.[eventDetailType.name])}
                                                </p>
                                            </div>
                                        </ToggleButton>
                                    );
                                })}
                        </ToggleButtonGroup>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="button is-fullwidth content-center" onClick={handleClose}>
                        {t('BUTTONS.CLOSE')}
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
