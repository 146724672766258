import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CloseIcon from '@mui/icons-material/Close';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
interface ContentsDialogProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: any) => void;
  defaultValue?: string;
}
const schema = z.object({
  name: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
});
export type FormContentsData = z.infer<typeof schema>;
const ContentsDialog: React.FC<ContentsDialogProps> = ({
  open,
  handleClose,
  onSubmit,
  defaultValue,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormContentsData>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: defaultValue,
    },
  });
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <TextRotationNoneIcon />
        {'แก้ไขข้อความ'}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="px-5">
            <TextField
              multiline
              rows={4}
              label="ข้อความ"
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              margin="normal"
              inputProps={{ borderRadius: '8px' }}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ borderRadius: '8px', alignItems: 'center', gap: 1 }}
            variant="contained"
          >
            <CloseIcon />
            ปิดหน้าต่าง
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              color: 'black',
              backgroundColor: '#B4E380',
              '&:hover': {
                backgroundColor: '#88D66C',
              },
              borderRadius: '8px',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <ThumbUpIcon />
            ยันยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContentsDialog;
