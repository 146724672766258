import { z } from 'zod';

export const ProfileFormSchema = z.object({
  firstName: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
  lastName: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
  email: z
    .string()
    .nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
    .email({ message: 'อีเมลไม่ถูกต้อง' }),
  tel: z
    .string()
    .nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
    .regex(/^\d{10,}$/, { message: 'กรอกตัวเลขอย่างน้อย 10 หลัก' }),
});

export type ProfileFormInput = z.infer<typeof ProfileFormSchema> & { id?: number | undefined };
