import React from 'react';
import cn from '@src/utils/class-names';

type CompensateStatusBadgeProps = {
  compensateStatus: string;
};

const CompensateStatusBadge: React.FC<CompensateStatusBadgeProps> = ({ compensateStatus }) => {
  const statusClass = cn(
    'inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-secondary-foreground hover:bg-secondary/80',
    compensateStatus === 'COMPENSATED'
      ? 'bg-emerald-100 text-emerald-500'
      : compensateStatus === 'WAIT_FOR_COMPENSATION'
        ? 'bg-amber-100 text-amber-500'
        : 'bg-emerald-100 text-emerald-500'
  );

  return <div className={statusClass}>{compensateStatus}</div>;
};

export default CompensateStatusBadge;
