export const eventType = [
  { id: 'setTransporter', name: 'DETAIL_PAGE.EVENT_TYPE.TRANSPORTER' },
  { id: 'setFoodDrink', name: 'DETAIL_PAGE.EVENT_TYPE.FOOD_DRINK' },
  { id: 'setRestPlace', name: 'DETAIL_PAGE.EVENT_TYPE.REST_PLACE' },
  { id: 'setWaste', name: 'DETAIL_PAGE.EVENT_TYPE.WASTE' },
  { id: 'setElectricSite', name: 'DETAIL_PAGE.EVENT_TYPE.ELECTRIC_SITE' },
  { id: 'setElectric', name: 'DETAIL_PAGE.EVENT_TYPE.ELECTRIC' },
  { id: 'setGift', name: 'DETAIL_PAGE.EVENT_TYPE.GIFT' },
  { id: 'setExpress', name: 'DETAIL_PAGE.EVENT_TYPE.EXPRESS' },
];

export const eventEFValue: Record<string, Array<Number>> = {
  setTransporter: [32.434],
  setFoodDrink: [3.93, 0.217],
  setRestPlace: [14.21],
  setWaste: [2.3896],
  setElectricSite: [0.003, 0.0258],
  setElectric: [0.4999],
  setGift: [11.5],
  setExpress: [50.0063],
};

export const eventTypeIcon: Record<string, string> = {
  setTransporter: 'fa-car',
  setFoodDrink: 'fa-burger',
  setRestPlace: 'fa-bed',
  setWaste: 'fa-dumpster',
  setElectricSite: 'fa-bolt',
  setElectric: 'fa-bolt',
  setGift: 'fa-gift',
  setExpress: 'fa-truck-front',
};
